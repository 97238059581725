import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import user from "../../images/task/user.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import img from "../../images/6274 1.svg";
export default function AddEvent({ show, onHide,props}) {
    // console.log(props,"propssss")
  const [addButton, setAddButton] = useState(false);
  // const [contacts, setContacts] = useState();
  const [file, setFile] = useState();
  const [name, setName] = useState();
  const [loader, setLoader] = useState(true);
  // const navigate = useNavigate();
  // delete data
  // const handleDeleteClick = (contactId) => {
  //   const newContacts = [...contacts];
  //   const index = contacts.findIndex((contact) => contact.id === contactId);
  //   newContacts.splice(index, 1);
  //   setContacts(newContacts);
  // };

  //   async function handleAddFormSubmit(event) {
  //     event.preventDefault();
  //     setAddButton(true)
  //     const res = await addCategoryApi(name, file);
  //     setLoader(false);
  //     if (res?.status === 200) {
  //       setAddButton(false);
  //       categoryLists();
  //       toast.success("Success Notification !", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }else if(res.status===401){
  //       toast.error("Authentication failed !", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       history.push("/login")
  //   }else{
  //     toast.error("error Notification !", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  //     onHide(false);
  //   }
function addEvent(){
  onHide();
  props.history.push("/event-form");
  
}
function noEvent(){
  onHide();
  props.history.push("/personalize-profile");
  
}

  return (
    <Modal
      show={show}
      onHide={onHide}
      // backdrop="static"
      // keyboard={false}
      centered
    >
      <Modal.Body>
        <div className="modal-header p-0 border-bottom-0">
          <h3 className="modal-title ">
            Does your Business Host Routine Events ?
          </h3>
          <button type="button" className="btn close" onClick={() => onHide()}>
            <span>×</span>
          </button>
        </div>

        <div className="text-center">
          <img src={img} alt="image" />
          <div className="d-flex flex-column mt-5">
            <button className="btn btn-secondary light mb-3" onClick={noEvent}>
              No Routine Events
            </button>
            <button
              className="btn text-white"
              style={{ backgroundColor: "#F36C03", border: "none" }}
              onClick={addEvent}
            >
              Add Event
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
