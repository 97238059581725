import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import img from "../../images/confetti 1.svg";
import arrow from "../../images/left-arrow (5) 1.svg";
export default function Congratulation({ show, onHide, props }) {
  // console.log(props, "propssss");

  function redirect() {
    onHide();
    props.history.push("/personalize-profile");
  }

  return (
    <Modal
      className="sortBy"
      show={show}
      // backdrop="static"
      // keyboard={false}
      centered
    >
      <Modal.Body>
        <div className="modal-header p-0 border-bottom-0">
         
        </div>

        <div className="text-center">
          <img src={img} alt="image" />
          <h3>CONGRATULATIONS !</h3>
          <p>Your event has been posted successfully.</p>
<div onClick={redirect}>
<img src={arrow} />
</div>
          
        </div>
      </Modal.Body>
    </Modal>
  );
}
