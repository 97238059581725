import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { viewUserDetails } from "../components/APIs/Api";
import { variable, s3BaseUrl } from "../components/Variable";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";
import S3Image from "../components/S3Image";

export default function UserDetail() {
  const [UserDetails, setUserDetails] = useState();
  const [loading, setLoding] = useState(true);

  //===============fetch user details Api  =================
  async function viewUserDetailsApi() {
    const res = await viewUserDetails(variable.id);
    setUserDetails(res);

    setLoding(false);
  }

  useEffect(() => {
    viewUserDetailsApi();
  }, []);

  let item = UserDetails?.data?.data;

  return (
    <>
      <div>
        <div className="page-titles">
          <h4>Users Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="user-management">Users</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link>Users Details</Link>
            </li>
          </ol>
        </div>
        {/* <Col> */}
        {loading ? (
          <Lottie
            style={{ height: "200px", marginTop: "45px", padding: "30px" }}
            animationData={animationData}
          />
        ) : (
          <Card style={{ width: "70%" }}>
            <Card.Header>
              <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
                <div>
                  <S3Image imageKey={item.image} title={"details"} />
                </div>
                <div className="text-left" style={{ flex: "1" }}>
                  <h3 className="text-color ">
                    {item?.userName.length > 10
                      ? item?.userName.slice(0, 20) + "..."
                      : item?.userName}
                  </h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Update Profile</h5>
                    <p>{moment(item?.updatedAt).format("ll")}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 style={{ margin: "0" }}>Email</h5>
                    <p style={{ margin: "0" }}>{item?.email}</p>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <h5>Created At</h5>
                  <p className="m-0">{moment(item?.createdAt).format("ll")}</p>
                </div>

                <div className="d-flex justify-content-between align-items-center pb-3">
                  <h5>About Us</h5>
                  <p className="m-0">{item?.aboutUs}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}

        {/* </Col> */}
      </div>
    </>
  );
}
