import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "../layouts/PageTitle";
import { eventApi } from "../components/APIs/Api";
import { variable, s3BaseUrl } from "../components/Variable";
import FilterData from "../components/Filters/FilterData";
import { eventsTyprOption } from "../components/Filters/FilterLables";
import S3Image from "../components/S3Image";

export default function EventsList() {
  const [loader, setLoader] = useState(true);
  const [eventList, setEventList] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterType, setFilterType] = useState("All");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let list = eventList?.data?.data?.events;

  //==============eventList===================
  async function hendleEvent() {
    setLoader(true);
    const res = await eventApi(variable.id, startDate, endDate, filterType);
    setEventList(res);
    setLoader(false);
  }

  useEffect(() => {
    scrollToTop();
    hendleEvent();
  }, [filterType]);

  useEffect(() => {
    hendleEvent();
  }, []);

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <PageTitle activeMenu="Events" motherMenu="Vendor" />
        <div className="ml-3">
          <FilterData
            FilterOption={eventsTyprOption}
            setFilterType={setFilterType}
            // value={filterType}
          />
        </div>

        {eventList?.data?.data?.events?.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h2>No Events</h2>
          </div>
        ) : (
          <Col>
            {loader ? (
              <div className="">
                <Lottie
                  style={{
                    height: "200px",
                    marginTop: "45px",
                    padding: "30px",
                  }}
                  animationData={animationData}
                />
              </div>
            ) : (
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead style={{ color: "black" }}>
                      <tr>
                        <th>
                          <strong>image</strong>
                        </th>
                        <th>
                          <strong>Event Name</strong>
                        </th>
                        <th>
                          <strong>Day</strong>
                        </th>
                        <th>
                          <strong>Event Type</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list?.map((item, index) => (
                        <tr key={item._id}>
                          <td>
                            <S3Image imageKey={item?.image} />
                          </td>
                          <td>{item.title}</td>
                          <td>{item.days}</td>
                          <td>{item.eventType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            )}
          </Col>
        )}

        {/* {loader && <Spinner />} */}
      </div>
    </>
  );
}
