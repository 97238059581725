import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { totalHappyhourApi } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import Pagination from "../components/common/Pagination";
import moment from "moment";
import animationData from "..//../images/loding.json";
import { toast, ToastContainer } from "react-toastify";
export default function TotalHappyHours(props) {
  const [loader, setLoader] = useState(true);
  const [totalHappyHourList, setTotalHappyHourList] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 10;

  //=================fetch total Happy Hours api=================
  async function hendleHappyHour() {
    setLoader(true);
    const res = await totalHappyhourApi(currentPage, itemsPerPage);
    setTotalHappyHourList(res);
    setLoader(false);
  }

  const totalItems = totalHappyHourList?.data?.data?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    hendleHappyHour();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    hendleHappyHour();
  }, []);

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <PageTitle activeMenu="Happy Hours" motherMenu="Total" />
        {totalHappyHourList?.data?.data?.happyHours?.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h2>No happyhour</h2>
          </div>
        ) : (
          <Col>
            {loader ? (
              <div className="">
                <Lottie
                  style={{
                    height: "200px",
                    marginTop: "45px",
                    padding: "30px",
                  }}
                  animationData={animationData}
                />
              </div>
            ) : (
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead style={{ color: "black" }}>
                      <tr>
                        <th>
                          <strong>DAY</strong>
                        </th>
                        <th>
                          <strong>vendor NAME</strong>
                        </th>
                        <th>
                          <strong>START TIME</strong>
                        </th>
                        <th>
                          <strong>END TIME</strong>
                        </th>
                        <th>
                          <strong>DEALS</strong>
                        </th>
                        <th>
                          <strong>Offer</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalHappyHourList?.data?.data?.happyHours?.map(
                        (item) => (
                          <tr key={item._id}>
                            <td>{item.day}</td>
                            <td>{item.name}</td>

                            {moment(item?.startTime).format("LT") ===
                            "Invalid date" ? (
                              <td>{item?.startTime}</td>
                            ) : (
                              <td>{moment(item?.startTime).format("LT")}</td>
                            )}

                            {moment(item?.endTime).format("LT") ===
                            "Invalid date" ? (
                              <td>{item?.endTime}</td>
                            ) : (
                              <td>{moment(item?.endTime).format("LT")}</td>
                            )}
                            <td>
                              {item?.deals?.map((ele, i) => (
                                <div>
                                  {ele.deal.length > 30
                                    ? ele.deal.slice(0, 30) + "..."
                                    : ele.deal}
                                </div>
                              ))}
                            </td>
                            <td>
                              {item?.deals?.map((ele, i) => (
                                <div className="">
                                  {ele.type === "value" && (
                                    <span>${ele.price} Off</span>
                                  )}
                                  {ele.type === "Fixed" && (
                                    <span>${ele.price}</span>
                                  )}
                                  {ele.type === "percentage" && (
                                    <span>{ele.price}% Off</span>
                                  )}
                                  {ele.type === "2 for 1" && (
                                    <div className="d-flex flex-column">
                                      <div>${ele.price}(2 for 1)</div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  {totalHappyHourList?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Total HappyHours{" "}
                          <span
                            style={{
                              color: "rgb(243, 108, 3)",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {totalItems}
                          </span>
                        </div>
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        )}
        {/* {loader && <Spinner />} */}
      </div>
    </>
  );
}
