import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import calaneder from "../../images/calendar.svg";
import calanederWhite from "../../images/calendar 2.svg";
import Congratulation from "../modal/Congratulation";
import { createEvent } from "../components/APIs/Api";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { uploadFile } from "react-s3";
import AWS from "aws-sdk";

function EventForm(props) {
  const s3 = new AWS.S3();

  const userId = localStorage.getItem("VendorId");
  const [congratsModal, setCongratsModal] = useState(false);
  const [next, setNext] = useState(false);
  const [image, setImage] = useState("");
  const [selectImage, setSelectImage] = useState();
  const [location, setLocation] = useState({
    address: "",
    lat: 0,
    long: 0,
  });
  const [imageUrl, setImageUrl] = useState("");
  const [loader, setLoader] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let errorsObj = {
    title: "",
    eventType: "",
    performance: "",
    days: [],
    startTime: "",
    endTime: "",
    about: "",
    image: "",
    bookTicket: "",
    bookTicketTitle: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    title: "",
    userId: userId,
    eventType: "",
    performance: "",
    days: [],
    startTime: "",
    endTime: "",
    about: "",
    bookTicket: "",
    bookTicketTitle: "",
  });

  //====================s3Keys===================

  //=============convert Time====================
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const time = new Date();
    const name = e.target.name;

    const [hours, minutes] = inputValue.split(":");

    time.setHours(hours);
    time.setMinutes(minutes);
    const formattedTime = time.toISOString();
    setFormData({ ...formData, [name]: formattedTime });
  };

  //======================Location send=====================
  const handleAddress = (place) => {
    setLocation({ address: place?.label });

    geocodeByAddress(place.label)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((res) => {
        setLocation((prevLocation) => ({
          ...prevLocation,
          lat: res.lat,
          long: res.lng,
        }));
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };

  useEffect(() => {
    const handleUpload = async () => {
      if (!image) {
        console.error("No file selected");
        return;
      }

      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: image?.name,
        Body: image,
      };

      try {
        const data = await s3.upload(params).promise();
        setSelectImage(data);
      } catch (error) {
        console.error("Upload failed:", error);
      }
    };

    handleUpload();
  }, [image]);

  //===================OnSubmit Events=======================
  async function onSubmit(e, template) {
    e.preventDefault();
    scrollToTop();
    setLoader(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.title === "") {
      errorObj.title = "This field is required";
      error = true;
    }
    if (formData.eventType === "") {
      errorObj.eventType = "This field is required";
      error = true;
    }
    if (formData.startTime === "") {
      errorObj.startTime = "This field is required";
      error = true;
    }
    if (formData.endTime === "") {
      errorObj.endTime = "This field is required";
      error = true;
    }
    if (formData.about === "") {
      errorObj.about = "This field is required";
      error = true;
    }
    if (formData.image === "") {
      errorObj.image = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    //////=======================Event Api==========================
    const response = await createEvent(
      formData,
      selectImage.key,
      template,
      location
    )
      .then((response) => {
        console.log(response, "data");
        setLoader(false);
        setCongratsModal(true);
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error(`❌${error?.response?.data?.data}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      });
  }
  return (
    <>
      {loader ? (
        <div className="">
          <Lottie
            style={{
              height: "200px",
              marginTop: "45px",
              padding: "30px",
            }}
            animationData={animationData}
          />
        </div>
      ) : (
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <PageTitle activeMenu="Add Event Details" motherMenu="Vendor" />
          <div className="col-lg-12 mb-5">
            <div className="authincation-content text-black p-5">
              <div className="mb-4">
                <h3 className="mb-1 font-w600 text-black ">
                  Tell Us About your Event
                </h3>
              </div>

              <form>
                <div className="form-group">
                  <label className="mb-2 ">Event Title</label>
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                    placeholder="Enter Event Title"
                  />
                  {errors.title && (
                    <div className="text-danger fs-12">{errors.title}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Type of Event</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      value={formData.eventType}
                      onChange={(e) =>
                        setFormData({ ...formData, eventType: e.target.value })
                      }
                      required
                    >
                      <option hidden>Select..</option>
                      <option value="Live Music">Live Music</option>
                      <option value="Trivia">Trivia</option>
                      <option value="Karaoke">Karaoke</option>
                      <option value="Tournament">Tournament</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  {formData.eventType === "Live Music" && (
                    <div className="form-group mt-3">
                      <label className="text-black font-w500">
                        Performance By
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        required="required"
                        value={formData.performance}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            performance: e.target.value,
                          })
                        }
                        placeholder="Enter Performance By"
                      />
                    </div>
                  )}

                  {errors.eventType && (
                    <div className="text-danger fs-12">{errors.eventType}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">Address</label>
                  <GooglePlacesAutocomplete
                    className="form-control"
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      placeholder: "Enter Address",
                      onChange: (place) => {
                        handleAddress(place);
                        console.log(JSON.stringify(place), "hcfgvjb");
                      },
                    }}
                  />
                  {errors.address && (
                    <div className="text-danger fs-12">{errors.address}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">Day(s)</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      required="required"
                      value={formData.days}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          days: Array.from(
                            e.target.selectedOptions,
                            (option) => option.value
                          ),
                        })
                      }
                    >
                      <option hidden>Select..</option>
                      <option>Monday</option>
                      <option>Tuesday</option>
                      <option>Wednesday</option>
                      <option>Thursday</option>
                      <option>Friday</option>
                      <option>Saturday</option>
                      <option>Sunday</option>
                    </select>
                  </div>
                  {errors.days && (
                    <div className="text-danger fs-12">{errors.days}</div>
                  )}
                </div>
                <div className="form-group d-flex">
                  <div className="w-100 mr-2">
                    <label className="mb-2 ">Start Time</label>
                    <input
                      type="time"
                      className="form-control"
                      required="required"
                      defaultValue={formData.startTime}
                      onChange={handleInputChange}
                      name="startTime"
                    />
                    {errors.startTime && (
                      <div className="text-danger fs-12">
                        {errors.startTime}
                      </div>
                    )}
                  </div>
                  <div className="w-100">
                    <label className="mb-2 ">End Time</label>
                    <input
                      type="time"
                      className="form-control"
                      required="required"
                      defaultValue={formData.endTime}
                      onChange={handleInputChange}
                      name="endTime"
                    />
                    {errors.endTime && (
                      <div className="text-danger fs-12">{errors.endTime}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="text-black font-w500">About</label>
                  <textarea
                    type="text"
                    className="form-control"
                    required="required"
                    value={formData.about}
                    onChange={(e) =>
                      setFormData({ ...formData, about: e.target.value })
                    }
                    placeholder="Type here..."
                  />
                  {errors.about && (
                    <div className="text-danger fs-12">{errors.about}</div>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="text-black font-w500">Upload Image</label>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className="form-control"
                    required="required"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setImageUrl(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                  {errors.image && (
                    <div className="text-danger fs-12">{errors.image}</div>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="text-black font-w500">
                    Book your Tickets
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.bookTicket}
                    onChange={(e) =>
                      setFormData({ ...formData, bookTicket: e.target.value })
                    }
                    placeholder="Enter link here"
                  />
                  {errors.bookTicket && (
                    <div className="text-danger fs-12">{errors.bookTicket}</div>
                  )}
                </div>

                <div className="form-group mt-3">
                  <label className="text-black font-w500">
                    One word for ticket
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.bookTicketTitle}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bookTicketTitle: e.target.value,
                      })
                    }
                    placeholder="Enter a hyperlink for tickets"
                  />
                </div>
                <div className="text-right ">
                  <button
                    type="button"
                    className="btn text-white   mr-3"
                    style={{ backgroundColor: "#F36C03", border: "none" }}
                    onClick={() => setNext(true)}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
          {next && (
            <>
              <div>
                <h3 className="text-black">Choose Posting Template</h3>
              </div>
              <div className="grid-fit-content d-flex gap-1">
                <Card className="template-card">
                  <Card.Header>
                    <Card.Title>Basic 2</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="text-center ">
                      <h3 className="text-black">
                        Celebration Night With Live Music
                      </h3>
                      <p>PERFORMANCE BY:</p>
                      <p className="border-bottom text-black">
                        {formData.performance}
                      </p>
                      <div style={{ height: "350px" }}>
                        <img
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "315px",
                            borderRadius: "15px",
                          }}
                          src={imageUrl}
                        />
                      </div>
                    </div>

                    <p className="color-text mt-3 mb-1">MUSIC EVENT</p>
                    <p className="fs-20 text-black">{formData.eventType}</p>
                    <div className="d-flex border-bottom">
                      <img src={calaneder} width={60} height={60} />
                      <div className="ml-2">
                        <p className="mb-0 text-black">Time</p>
                        <p>{formData.startTime}</p>
                      </div>
                    </div>
                    <p className="mt-3 text-black">Book your Tickets</p>
                    <a
                      href={formData.bookTicket}
                      className="form-control border-0 text-black "
                    >
                      {formData.bookTicketTitle}
                    </a>
                    <p className="mt-3 text-black border-top pt-3">
                      Description
                    </p>
                    <p className="border-0 bg-grey p-2">
                      {" "}
                      {formData.about.length > 20
                        ? formData.about.slice(0, 20) + "..."
                        : formData.about}
                    </p>
                    <div className="text-right mt-5">
                      <button
                        type="button"
                        className="btn text-white   mr-3"
                        style={{ backgroundColor: "#F36C03", border: "none" }}
                        onClick={(e) => {
                          // setFormData({ ...formData, template: "template1" });
                          onSubmit(e, "Basic 2");
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="template-card">
                  <Card.Header>
                    <Card.Title>Basic</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="text-center">
                      <div style={{ height: "350px" }}>
                        <img
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "315px",
                            borderRadius: "15px",
                          }}
                          src={imageUrl}
                        />
                      </div>{" "}
                      <div className="text-left">
                        <h3 className="text-black mt-4">
                          Celebration Night With Live Music
                        </h3>
                        <p>PERFORMANCE BY:</p>
                        <p className="border-bottom text-black">
                          {formData.performance}
                        </p>
                      </div>
                    </div>

                    <p className="color-text mt-3 mb-1">MUSIC EVENT</p>
                    <p className="fs-20 text-black">{formData.eventType}</p>
                    <div className="d-flex border-bottom">
                      <img src={calaneder} width={60} height={60} />
                      <div className="ml-2">
                        <p className="mb-0 text-black">Time</p>
                        <p>{formData.startTime}</p>
                      </div>
                    </div>
                    <p className="mt-3 text-black">Book your Tickets</p>
                    <Link
                      to={formData.bookTicket}
                      className="form-control border-0 text-black"
                    >
                      {formData.bookTicketTitle}
                    </Link>
                    <p className="mt-3 text-black border-top pt-3">
                      Description
                    </p>
                    <p className="border-0 bg-grey p-2">
                      {" "}
                      {formData.about.length > 20
                        ? formData.about.slice(0, 20) + "..."
                        : formData.about}
                    </p>
                    <div className="text-right mt-5">
                      <button
                        type="button"
                        className="btn text-white   mr-3"
                        style={{ backgroundColor: "#F36C03", border: "none" }}
                        onClick={(e) => {
                          // setFormData({ ...formData, template: "template2" });
                          onSubmit(e, "Basic");
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="grid-fit-content d-flex gap-1">
                <Card className="template-card">
                  <Card.Header>
                    <Card.Title>Vertical</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex align-items-start">
                      <div style={{ height: "600px" }}>
                        <img
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "175px",
                            borderRadius: "15px",
                          }}
                          src={imageUrl}
                        />
                      </div>{" "}
                      <div className="pl-2 w-50">
                        <div>
                          <div className="">
                            <h3 className="text-black fs-18">
                              Celebration Night With Live Music
                            </h3>
                            <p>PERFORMANCE BY:</p>
                            <p className="border-bottom text-black">
                              {formData.performance}
                            </p>
                          </div>

                          <p className="color-text mt-1 mb-1">MUSIC EVENT</p>
                          <p className="fs-16 text-black">
                            {formData.eventType}
                          </p>
                          <div className="d-flex border-bottom align-items-center">
                            <img src={calaneder} width={40} height={40} />
                            <div className="ml-2">
                              <p className="mb-0 text-black">Time</p>
                              <p className="mb-0">{formData.startTime}</p>
                            </div>
                          </div>
                          <p className="mt-1 text-black fs-14">
                            Book your Tickets
                          </p>
                          <Link
                            to={formData.bookTicket}
                            className="form-control border-0 text-black"
                          >
                            {formData.bookTicketTitle}
                          </Link>
                          <p className="text-black border-top pt-3 fs-14">
                            Description
                          </p>
                          <p className=" border-0 bg-grey">
                            {formData.about.length > 20
                              ? formData.about.slice(0, 20) + "..."
                              : formData.about}
                          </p>
                        </div>

                        <div className="mt-5 text-right">
                          <button
                            type="button"
                            className="btn text-white"
                            onClick={(e) => {
                              // setFormData({ ...formData, template: "template3" });
                              onSubmit(e, "Vertical");
                            }}
                            style={{
                              backgroundColor: "#F36C03",
                              border: "none",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="template-card">
                  <Card.Header>
                    <Card.Title>Poster</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div
                      className="row"
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}${imageUrl})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "15px",
                      }}
                    >
                      <div className="col-5"></div>
                      <div className="col-7 text-white">
                        <div className="">
                          <h3 className="text-white fs-18">Open Jam Night</h3>
                          <p>PERFORMANCE BY:</p>
                          <p className="border-bottom text-white">
                            {formData.performance}
                          </p>
                        </div>

                        <p className="bg-white text-black rounded mt-1 mb-1 p-1 fs-12 ">
                          MUSIC EVENT
                        </p>
                        <p className="fs-16 text-white">{formData.eventType}</p>
                        <div className="d-flex border-bottom align-items-center">
                          <img src={calanederWhite} width={40} height={40} />
                          <div className="ml-2">
                            <p className="mb-0 text-white">Time</p>
                            <p className="mb-0">{formData.startTime}</p>
                          </div>
                        </div>
                        <p className="mt-1 text-white fs-14">
                          Book your Tickets
                        </p>
                        <Link
                          to={formData.bookTicket}
                          className="border-0 text-white"
                        >
                          {formData.bookTicketTitle}
                        </Link>
                        <p className="text-white border-top pt-3 fs-14">
                          Description
                        </p>
                        <p className=" border-0 bg-grey">
                          {" "}
                          {formData.about.length > 20
                            ? formData.about.slice(0, 20) + "..."
                            : formData.about}
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 text-right">
                      <button
                        type="button"
                        className="btn text-white"
                        onClick={(e) => {
                          // setFormData({ ...formData, template: "template4" });
                          onSubmit(e, "Poster");
                        }}
                        style={{ backgroundColor: "#F36C03", border: "none" }}
                      >
                        Submit
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </>
          )}

          <Congratulation
            show={congratsModal}
            onHide={() => setCongratsModal(false)}
            props={props}
          />
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userId: state.UserData.userId,
  };
};
export default connect(mapStateToProps)(EventForm);
