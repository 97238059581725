import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Badge, Button, Card, Col, Dropdown, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Pagination from "../components/common/Pagination";
import moment from "moment";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { AccessedAccountListApi } from "../components/APIs/Api";

export default function AccessedAccount() {
  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 10;

  //===================pagination=================
  const totalItems = userList?.data?.data?.total || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  async function handleAccountList() {
    setLoader(true);
    try {
      const res = await AccessedAccountListApi(itemsPerPage, currentPage);
      setUserList(res);
      setLoader(false);
    } catch (err) {
      toast.error(`❌${err?.response?.data?.data}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    scrollToTop();
    handleAccountList();
  }, [currentPage]);

  console.log(userList);

  return (
    <div>
      <PageTitle activeMenu="Accessed Account" motherMenu="List" />
      <Col>
        {loader ? (
          <div className="">
            <Lottie
              style={{ height: "200px", marginTop: "45px", padding: "30px" }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Username</strong>
                    </th>
                    <th>
                      <strong>email</strong>
                    </th>
                    <th>
                      <strong>updatedAt</strong>
                    </th>
                    <th>
                      <strong>Time</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.data?.data?.vendors?.map((item) => (
                    <tr>
                      <td>{item?.userName}</td>
                      <td>{item?.email}</td>
                      <td>{moment(item?.updatedAt).format("ll")}</td>
                      <td>{moment(item?.updatedAt).format("LT")}</td>{" "}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {userList?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Total Accessed {totalItems}
                      </span>
                    </div>
                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
    </div>
  );
}
