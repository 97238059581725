import axios from "axios";

import axiosInstance from "../../../services/AxiosInstance";

const BlockServiceUrl = "http://44.227.196.81:5000/api/v1/admin/user";

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get(`admin/dashBoard`, {});
  return response;
}
export async function dashboardChartApi() {
  const response = await axiosInstance.get(`admin/getChart`, {});
  return response;
}
export async function userManagementList(
  search,
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  filterType,
  city
) {
  const response = await axiosInstance.get(
    `admin/allUser?search=${search}&page=${currentPage}&limit=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&aboutUs=${filterType}&city=${city}`
  );
  return response;
}

export async function viewUserDetails(userId) {
  const response = await axiosInstance.get(`admin/userInfo?userId=${userId}`);
  return response;
}

export async function servicesProviderListApi(
  search,
  startDate,
  endDate,
  currentPage,
  itemsPerPage,
  filterType
) {
  const response = await axiosInstance.get(
    `admin/getVendor?search=${search}&page=${currentPage}&limit=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${filterType}`
  );
  return response;
}

export async function paymentListApi(currentPage, itemsPerPage) {
  const responce = await axiosInstance.get(
    `admin/payments?page=${currentPage}&limit=${itemsPerPage}`
  );
  return responce;
}

export async function servicesProviderDetailsApi(vendorId) {
  const response = await axiosInstance.get(
    `admin/vendorInfo?vendorId=${vendorId}`
  );
  return response;
}

export async function happyhourApi(vendorId, startDate, endDate) {
  const response = await axiosInstance.get(
    `admin/getHappyHours?vendorId=${vendorId}&page=${0}&limit=${10}&startDate=${startDate}&endDate=${endDate}`
  );
  return response;
}

export async function eventApi(vendorId, startDate, endDate, filterValue) {
  const response = await axiosInstance.get(
    `admin/getEvents?vendorId=${vendorId}&page=${0}&limit=${10}&type=${filterValue}&startDate=${startDate}&endDate=${endDate}`
  );
  return response;
}

export async function totalHappyhourApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `admin/happyHoursList?page=${currentPage}&limit=${itemsPerPage}`
  );
  return response;
}
export async function getHappyHourDetailsApi(id) {
  const response = await axiosInstance.get(`admin/singleHappyHour?Id=${id}`);
  return response;
}

export async function totalEventApi(
  currentPage,
  itemsPerPage,
  filterType,
  template
) {
  const response = await axiosInstance.get(
    `/admin/eventsList?page=${currentPage}&limit=${itemsPerPage}&type=${filterType}&template=${template}`
  );
  return response;
}

export async function SuggestedCityApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `admin/getCities?page=${currentPage}&limit=${itemsPerPage}`
  );
  return response;
}
export async function AccessedAccountListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `admin/loginVendors?page=${currentPage}&limit=${itemsPerPage}`
  );
  return response;
}
/////////////////////////////////--PUT REQ--/////////////////////////////////////
export async function blockUserApi(id, status) {
  const data = { status: status, userId: id };
  const response = await axiosInstance.put(`admin/userAction`, data);
  return response;
}

export async function blockServiceApi(id, status) {
  const data = { status: status };
  const response = await axiosInstance.put(`${BlockServiceUrl}/${id}`, data);
  return response;
}

export async function onChangePasswoardApi(oldPassword, newPassword) {
  const data = { oldPassword, newPassword };
  const response = await axiosInstance.put(`admin/changePassword`, data);
  return response;
}
export async function personalizeProfile(
  about,
  websiteUrl,
  coverImage,
  additionalImages,
  userId
) {
  const postData = { about, websiteUrl, coverImage, additionalImages, userId };
  const response = await axiosInstance.put(
    `admin/professionalizeDetails`,
    postData
  );
  return response;
}
export async function paymentEnableApi(id) {
  const postData = { vendorId: id };
  const response = await axiosInstance.put(`admin/paymentAction`, postData);
  return response;
}
export async function editHappyHoursApi(happyhour, image, id) {
  const postData = {
    day: happyhour?.day,
    startTime: happyhour?.startTime,
    endTime: happyhour?.endTime,
    image: image,
    Id: id,

    deals: happyhour.deals.map((dealItem) => ({
      deal: dealItem.deal,
      price: dealItem.price,
      type: dealItem.type,
    })),
  };
  const response = await axiosInstance.put(`admin/editHappyHour`, postData);
  return response;
}
export async function editVendorDetailsApi(
  formData,
  operatingHour,
  location,
  image,
  phone
) {
  const postData = {
    userId: formData.userId,
    operatingHours: operatingHour,
    coverImage: image,
    typeOfBusiness: formData.typeOfBusiness,
    businessName: formData.businessName,
    PhoneNumber: formData.PhoneNumber,
    countryCode: phone,
    about: formData.about,
    websiteUrl: formData.websiteUrl,
    address: location.address,
    zipCode: location.zipCode,
    city: location.city,
    lat: location.lat,
    long: location.long,
  };
  const response = await axiosInstance.put(`/admin/editVendorDetail`, postData);
  return response;
}

//////////////////////////////--POST--//////////////////////////////////////

export async function addServiceProviderApi(image, userName, email) {
  const data = { image, userName, email };
  const response = await axiosInstance.post(`admin/createVendor`, data);
  return response;
}
export async function addVendorDetails(
  formData,
  happyHourInfo,
  opratingHour,
  userId,
  location,
  countryCode
) {
  console.log(formData);
  const postData = {
    userId: userId,
    typeOfBusiness: formData.typeOfBusiness,
    businessName: formData.businessName,
    city: location.city,
    zipCode: location.zipCode,
    PhoneNumber: formData.PhoneNumber,
    countryCode: countryCode,
    startTime: formData.startTime,
    endTime: formData.endTime,
    happyHourInfo,
    operatingHours: opratingHour,
    address: location.address,
    lat: location.lat,
    long: location.long,
  };
  const response = await axiosInstance.post(`admin/vendorDetail`, postData);
  return response;
}
export async function createEvent(formData, image, template, location) {
  console.log(image, "image in api function");
  const postData = {
    title: formData.title,
    userId: formData.userId,
    eventType: formData.eventType,
    performance: formData.performance,
    days: formData.days,
    date: null,
    type: "Recurring",
    startTime: formData.startTime,
    endTime: formData.endTime,
    about: formData.about,
    bookTicket: formData.bookTicket,
    bookTicketTitle: formData.bookTicketTitle,
    image,
    template,
    lat: location?.lat,
    address: location?.address,
    long: location?.long,
  };

  const response = await axiosInstance.post(`admin/createEvent`, postData);
  return response;
}

export async function sendEmailVendersApi() {
  const response = await axiosInstance.post(`admin/sendMails`);
  return response;
}
////////////////////////--DELETE REQ--//////////////////////////////

export async function deleteUserApi(userId) {
  const response = await axiosInstance.delete(
    `admin/deleteUser?userId=${userId}`
  );
  return response;
}

export async function deleteVenderApi(userId) {
  const response = await axiosInstance.delete(
    `admin/deleteUser?userId=${userId}`
  );
  return response;
}
export async function deleteHappyHourApi(id) {
  const response = await axiosInstance.delete(`admin/deleteHappyHour?Id=${id}`);
  return response;
}

export async function deleteHappyHourDealApi(dealId, happyHourId) {
  const response = await axiosInstance.delete(
    `admin/deleteDeals?dealId=${dealId}&happyHourId=${happyHourId}`
  );
  return response;
}
