import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Badge, Button, Card, Col, Dropdown, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Pagination from "../components/common/Pagination";
import moment from "moment";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { SuggestedCityApi } from "../components/APIs/Api";

export default function SuggestedCity() {
  const [loader, setLoader] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 10;

  //===================pagination=================
  const totalItems = cityList?.data?.data?.total || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  async function handleSuggestedCity() {
    setLoader(true);
    try {
      const res = await SuggestedCityApi(itemsPerPage, currentPage);
      setCityList(res);
      setLoader(false);
    } catch (err) {
      toast.error(`❌${err?.response?.data?.data}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    scrollToTop();
    handleSuggestedCity();
  }, [currentPage]);

  useEffect(() => {
    handleSuggestedCity();
  }, []);

  console.log(cityList);

  return (
    <div>
      <PageTitle activeMenu="Suggested City" motherMenu="List" />
      <Col>
        {loader ? (
          <div className="">
            <Lottie
              style={{ height: "200px", marginTop: "45px", padding: "30px" }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>City Name</strong>
                    </th>
                    <th>
                      <strong>Count</strong>
                    </th>
                    <th>
                      <strong>Last Search</strong>
                    </th>
                    <th>
                      <strong>Time</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cityList?.data?.data?.cities?.map((item) => (
                    <tr>
                      {/* <td>{item?.city}</td> */}
                      <td>
                        {item?.city
                          ? item.city.charAt(0).toUpperCase() +
                            item.city.slice(1)
                          : ""}
                      </td>
                      <td>{item?.count}</td>
                      <td>{moment(item?.updatedAt).format("ll")}</td>
                      <td>{moment(item?.updatedAt).format("LT")}</td>{" "}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {cityList?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Total Cites {totalItems}
                      </span>
                    </div>
                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
    </div>
  );
}
