import "../../layouts/nav/NavBar.css";

export default function FilterData({FilterOption,setFilterType,title }) {
  return (
    <>
      {/* {type === "off" ? (
        <div>
          <div>
            <select
              style={{ textAlign: "start", fontWeight: "bold" }}
              class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm mb-3"
              onChange={(e) => {
                setFilterType(e.target.value);
              }}
              value={value}
            >
              {FilterOption.map((item) => (
                <option
                  className="filter-options"
                  value={item.value}
                  key={item}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div></div>
      )}
 */}

        <div className="mb-3 ml-3">
          {" "}
          <label>{title}</label>
          <select
            style={{ textAlign: "start", fontWeight: "bold" }}
            class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
            onChange={(e) => {
              setFilterType(e.target.value);
            }}
          >
            {FilterOption.map((item) => (
              <option className="filter-options" value={item.value} key={item}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      
       {/* <div>
          <label>Vender offers</label>
          <select
            style={{ textAlign: "start", fontWeight: "bold" }}
            class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
            onChange={(e) => {
              setFilterType(e.target.value);
            }}
          >
            {FilterOption.map((item) => (
              <option className="filter-options" value={item.value} key={item}>
                {item.label}
              </option>
            ))}
          </select>
        </div> */}
    </>
  );
}
