import React from "react";
import { Pie } from "react-chartjs-2";

const HomeChart = ({ color1, color2, color3, height, chartData }) => {
    console.log(chartData,"in ")
    // const facebook=
   const data = {
      datasets: [
         {
            data: [chartData.instagram, chartData.tikTok, chartData.facebook,chartData.friendsAndfamily,chartData.others],
            borderWidth: 0,
            backgroundColor: [
               `${color1 ? color1 : "#3b4cb8"}`,
               `${color3 ? color3 : "violet"}`,
               `${color2 ? color2 : "rgba(89, 59, 219, 0.7)"}`,
               `${color3 ? color3 : "green"}`,
               `${color3 ? color3 : "orange"}`,

            ],
            hoverBackgroundColor: [
               `${color1 ? color1 : "#3b4cb8"}`,
               `${color3 ? color3 : "violet"}`,
               `${color2 ? color2 : "rgba(89, 59, 219, 0.7)"}`,
               `${color3 ? color3 : "green"}`,
               `${color3 ? color3 : "orange"}`,

            ],
         },
      ],
      labels: ["Instagram", "Tik Tok", "Facebook","Friends/Family","Others"],
   };

   const options = {
      responsive: true,
      legend: false,
      maintainAspectRatio: false,
   };

   return (
      <>
         <Pie data={data} height={height ? height : 200} options={options} />
      </>
   );
};

export default HomeChart;
