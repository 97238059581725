export const eventsTyprOption = [
  { label: "All", value: "All" },
  { label: "Upcoming", value: "Upcoming" },

  { label: "Previous", value: "Previous" },

  { label: "Recurring", value: "Recurring" },
];

export const userCommingPlatform = [
  { label: "All", value: "" },
  { label: "Facebook", value: "Facebook" },
  { label: "Instagram", value: "Instagram" },
  { label: "Tik-Tok", value: "Tik Tok" },
  { label: "Friends/Family", value: "Friends/Family" },
  { label: "Other", value: "Other" },
];
export const venderOffersOption = [
  { label: "All", value: "all" },
  { label: "Events", value: "events" },

  { label: "Happy Hours", value: "happyHours" },

  { label: "Both", value: "both" },
];

export const eventTypeOption = [
  { label: "All", value: "all" },
  { label: "Live Music", value: "Live Music" },

  { label: "Trivia", value: "Trivia" },

  { label: "Karaoke", value: "Karaoke" },
  { label: "Tournament", value: "Tournament" },
  { label: "Other", value: "Other" },
];
export const templateTypeOption = [
  { label: "All", value: "" },
  { label: "Basic", value: "Basic" },

  { label: "Vertical", value: "Vertical" },

  { label: "Basic 2", value: "Basic 2" },
  { label: "Poster", value: "Poster" },
];
