import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "../layouts/PageTitle";
import FilterSearch from "../components/Filters/FilterSearch";
import { eventApi, totalEventApi } from "../components/APIs/Api";
import Pagination from "../components/common/Pagination";
import FilterData from "../components/Filters/FilterData";
import {
  eventTypeOption,
  templateTypeOption,
} from "../components/Filters/FilterLables";
import S3Image from "../components/S3Image";

export default function TotalEvents() {
  const [loader, setLoader] = useState(true);
  const [totalEventList, setTotalEventList] = useState();
  const [filterType, setFilterType] = useState("all");
  const [template, setTemplate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 10;

  let list = totalEventList?.data?.data?.events;

  //=================fetch total Events api=================
  async function hendleEvent() {
    setLoader(true);
    const res = await totalEventApi(
      currentPage,
      itemsPerPage,
      filterType,
      template
    );
    setLoader(false);
    setTotalEventList(res);
  }
  const totalItems = totalEventList?.data?.data?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    hendleEvent();
  }, [template]);

  useEffect(() => {
    hendleEvent();
  }, [filterType]);

  useEffect(() => {
    scrollToTop();
    hendleEvent();
  }, [currentPage]);

  useEffect(() => {
    hendleEvent();
  }, []);

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <PageTitle activeMenu="Events" motherMenu="Total" />
        <div className="d-flex">
          <FilterData
            FilterOption={eventTypeOption}
            setFilterType={setFilterType}
            Type="off"
            title="Event Type"
          />
          <FilterData
            FilterOption={templateTypeOption}
            setFilterType={setTemplate}
            Type="off"
            title="Template Type"
          />
        </div>
        {totalEventList?.data?.data?.events?.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h2>No Events</h2>
          </div>
        ) : (
          <Col>
            {loader ? (
              <div className="">
                <Lottie
                  style={{
                    height: "200px",
                    marginTop: "45px",
                    padding: "30px",
                  }}
                  animationData={animationData}
                />
              </div>
            ) : (
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead style={{ color: "black" }}>
                      <tr>
                        <th>
                          <strong>image</strong>
                        </th>
                        <th>
                          <strong>Vendor Name</strong>
                        </th>
                        <th>
                          <strong>Event Name</strong>
                        </th>
                        <th>
                          <strong>Day</strong>
                        </th>
                        <th>
                          <strong>Event Type</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list?.map((item, index) => (
                        <tr key={item._id}>
                          {/* {console.log(usersList)} */}
                          <td>
                            <S3Image imageKey={item?.image} />
                          </td>
                          <td>{item.name}</td>

                          <td>{item.title}</td>
                          <td>{item.days}</td>
                          <td>{item.eventType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {totalEventList?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          {/* currentPage {currentPage * itemsPerPage + 1} totalPages{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "} */}
                          Total Events{" "}
                          <span
                            style={{
                              color: "rgb(243, 108, 3)",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {totalItems}
                          </span>
                        </div>
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        )}

        {/* {loader && <Spinner />} */}
      </div>
    </>
  );
}
