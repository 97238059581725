import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageTitle from "../layouts/PageTitle";
import "../modal/AddServiceProvider.css";
import AddServiceProvider from "../modal/AddServiceProvider";
import ShootEmail from "../modal/ShootEmail";
import {
  paymentListApi,
  servicesProviderListApi,
} from "../components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { variable, s3BaseUrl } from "../components/Variable";
import EditServiceProvider from "../modal/EditServiceProvider";
import FilterData from "../components/Filters/FilterData";
import { venderOffersOption } from "../components/Filters/FilterLables";
import { toast } from "react-toastify";
import { deleteVenderApi, paymentEnableApi } from "../components/APIs/Api";
import FilterSearch from "../components/Filters/FilterSearch";
import Pagination from "../components/common/Pagination";
import { useDispatch } from "react-redux";
import {
  saveIdInLocalStorage,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import S3Image from "../components/S3Image";

export default function ServiceProviderList(props) {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [filterType, setFilterType] = useState("all");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 10;

  //================getVender================
  async function servicsProvidersList() {
    setLoader(true);
    const res = await servicesProviderListApi(
      search,
      startDate,
      endDate,
      currentPage,
      itemsPerPage,
      filterType
    );
    setProviderList(res);
    setLoader(false);
  }

  //===================pagination=================
  const totalItems = providerList?.data?.data?.total || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //================delete Vender===================
  async function hendleDeleteVender(id) {
    console.log(id, "userbockid");
    const res = await deleteVenderApi(id);
    if (res.status === 200) {
      toast.success("Delete succesful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      servicsProvidersList();
    } else {
      toast.error("error !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  //=================enable Desable payment==============
  async function hendleEnablePayment(id) {
    console.log(id);
    const res = await paymentEnableApi(id)
      .then((response) => {
        if (response?.data?.data === true) {
          toast.success("Payment Enable", {
            position: toast.POSITION.TOP_RIGHT,
          });
          servicsProvidersList();
        } else {
          toast.success("Payment Disable", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.data, "errore");
        toast.error(`❌${error?.response?.data?.data}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  useEffect(() => {
    if (filterType.length > 0) {
      servicsProvidersList();
    }
  }, [filterType]);

  useEffect(() => {
    scrollToTop();
    servicsProvidersList();
  }, [currentPage]);

  useEffect(() => {
    if (search.length > 0) {
      setCurrentPage(0);
      servicsProvidersList();
    } else if (search.length === 0) {
      servicsProvidersList();
    }
  }, [search]);

  useEffect(() => {
    if (endDate.length > 0) {
      setCurrentPage(0);
      servicsProvidersList();
    } else if (endDate.length === 0) {
      servicsProvidersList();
    }
  }, [endDate]);

  useEffect(() => {
    scrollToTop();
    servicsProvidersList();
  }, []);

  console.log(providerList, "res");

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#f2f7f7" cx="5" cy="12" r="2"></circle>
        <circle fill="#f2f7f7" cx="12" cy="12" r="2"></circle>
        <circle fill="#f2f7f7" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [postModal, setPostModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <PageTitle activeMenu="Vendor List" motherMenu="Vendor Management" />
          <button
            className="btn btn-sm "
            style={{
              height: "40px",
              backgroundColor: "#F36C03",
              color: "#fff",
            }}
            onClick={() => setEmailModal(true)}
          >
            Shoot emails
          </button>
        </div>
        {/* //===============filterList============ */}
        <FilterSearch
          setSearch={setSearch}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          FilterOption={venderOffersOption}
          setFilterType={setFilterType}
          title={"Vendor Type"}
        />

        <div className="d-flex justify-content-end mb-3">
          <Button
            className="btn"
            style={{ backgroundColor: "#F36C03", border: "none" }}
            onClick={() => setPostModal(true)}
          >
            Add New +
          </Button>
        </div>

        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>
                      <th>
                        <strong>VENDOR NAME</strong>
                      </th>
                      <th>
                        <strong>VENDOR Email</strong>
                      </th>
                      <th>
                        <strong>PHONE</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {providerList?.data?.data?.vendor?.map((item) => (
                      <tr key={item.id}>
                        <td className="serviceProfile">
                          {/* <img
                            style={{ borderRadius: "30px" }}
                            height={50}
                            width={50}
                            src={`${s3BaseUrl}${item?.image}`}
                            alt="img"
                          /> */}
                          <S3Image imageKey={item?.image} />
                        </td>
                        <td>{item.userName}</td>
                        <td>{item.email}</td>
                        <td>
                          <span>{item.countryCode}</span>
                          {item.phoneNumber}
                        </td>
                        <td>
                          {item.isBlocked == false ? (
                            <Badge variant="success light">Active</Badge>
                          ) : (
                            <Badge variant="danger light">Blocked</Badge>
                          )}
                        </td>

                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="warning light"
                              className="light sharp btn  i-false "
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                // onClick={() => {
                                //   variable.id = item?._id;
                                //   props.history.push("/vendor-details");
                                // }}
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/vendor-details",
                                    state: { item: item },
                                  });
                                }}
                              >
                                View Details
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  // dispatch(setCurrentUserIdAction(item?._id));
                                  saveIdInLocalStorage(item?._id);
                                  props.history.push("/add-details");
                                }}
                              >
                                Add Details
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/edit-vendor",
                                    state: { item: item },
                                  });
                                }}
                              >
                                Edit Details
                              </Dropdown.Item> */}
                              <Dropdown.Item
                                onClick={() => {
                                  variable.id = item?._id;
                                  props.history.push("/events");
                                }}
                              >
                                Events
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/happy-hours",
                                    state: { item: item?._id },
                                  });
                                }}
                              >
                                Happy Hour
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  hendleDeleteVender(item._id);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                              {/* {item.isBlocked == false ? (
                                <Dropdown.Item
                                  onClick={() => hendleBlock(item.id, 0)}
                                >
                                  Deactive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => hendleBlock(item.id, 1)}
                                >
                                  Active
                                </Dropdown.Item>
                              )} */}
                              {item.isEnablePayment === false ? (
                                <Dropdown.Item
                                  onClick={() => hendleEnablePayment(item._id)}
                                >
                                  Enable
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => hendleEnablePayment(item._id)}
                                >
                                  Disable
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {providerList?.data?.data?.vendor?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
                {providerList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        <span
                          style={{
                            color: "rgb(243, 108, 3)",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Total Users {totalItems}
                        </span>
                      </div>
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
        <ShootEmail onHide={() => setEmailModal(false)} show={emailModal} />
        {postModal && (
          <AddServiceProvider
            show={postModal}
            servicsProvidersList={servicsProvidersList}
            onHide={() => setPostModal(false)}
          />
        )}
        {/* <EditServiceProvider
          show={editModal}
          servicsProvidersList={servicsProvidersList}
          onHide={() => setEditModal(false)}
        /> */}
      </div>
    </>
  );
}
