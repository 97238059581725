import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import AddEvent from "../modal/AddEvent";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";
import { addVendorDetails } from "../components/APIs/Api";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { uploadFile } from "react-s3";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import GoogleComponent from "react-google-autocomplete";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import AWS from "aws-sdk";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
function AddVenderDetails(props) {
  const s3 = new AWS.S3();
  const [selectImage, setSelectImage] = useState();
  const userId = localStorage.getItem("VendorId");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [loader, setLoader] = useState(false);
  const [location, setLocation] = useState({
    address: "",
    lat: 0,
    long: 0,
    zipCode: "",
    city: "",
  });
  let zipCode = "";
  let city = "";
  let errorsObj = {
    businessName: "",
    address: "",
    typeOfBusiness: "",
    city: "",
    zipCode: "",
    PhoneNumber: "",
    startTime: "",
    endTime: "",
    day: "",
    happyStartTime: "",
    happyEndTime: "",
    deal: [],
  };
  const [errors, setErrors] = useState(errorsObj);
  const [eventModal, setEventModal] = useState(false);
  const [numberError, setNumberError] = useState("");
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(0);
  const [applyToAllDay, setApplyToAllDay] = useState(false);

  ///==================opratingHour FormData=============
  const initialData = {
    startTime: "",
    endTime: "",
    status: false,
  };

  const [selectedDay, setSelectedDay] = useState(0);
  const [opratingHour, setOpratingHour] = useState(
    daysOfWeek.map((day) => ({
      day,
      ...initialData,
    }))
  );

  //============convert Time in 12hr=============================
  const convertTo12HourFormat = (time24hr) => {
    const [hour, minute] = time24hr.split(":");
    const amPm = parseInt(hour, 10) >= 12 ? "PM" : "AM";
    const hour12 = parseInt(hour, 10) % 12 || 12;
    return `${hour12}:${minute} ${amPm}`;
  };
  //=================convert time in 24hr=================
  const convertTo24HourFormat = (time12hr) => {
    if (!time12hr) {
      return "";
    }

    const parts = time12hr.split(" ");

    if (parts.length !== 2) {
      return "";
    }

    const [time, period] = parts;
    const [hour, minute] = time.split(":");

    if (hour === undefined || minute === undefined) {
      return ""; // Handle invalid time format
    }

    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 !== 12) {
      hour24 += 12;
    } else if (period === "AM" && hour24 === 12) {
      hour24 = 0;
    }

    return `${hour24.toString().padStart(2, "0")}:${minute}`;
  };

  //====================onChange function in Opreting hr================
  const handleOpratingInputChange = (e, dayIndex, key) => {
    let { value } = e.target;

    if (key === "startTime" || key === "endTime") {
      value = convertTo12HourFormat(value);
    }

    const updatedFormData = [...opratingHour];
    updatedFormData[dayIndex][key] = value;
    setOpratingHour(updatedFormData);
  };

  //======================Apply to all function =====================
  const applyToAll = () => {
    const selectedData = {
      startTime: opratingHour[selectedDay]?.startTime,
      endTime: opratingHour[selectedDay]?.endTime,
      status: opratingHour[selectedDay]?.status,
    };

    const updatedFormData = opratingHour.map((data) => ({
      ...data,
      ...selectedData,
    }));
    setOpratingHour(updatedFormData);
  };

  const toggleDayForm = (dayIndex) => {
    setSelectedDay(dayIndex);
  };

  //==============oprating day open and close ========================
  const handleOpratingcheck = (event, dayIndex, key) => {
    const updatedInfo = [...opratingHour];
    updatedInfo[dayIndex][key] = event.target.checked;
    setOpratingHour(updatedInfo);
    // console.log(event.target.checked);
  };

  //=============formData=============
  const [formData, setFormData] = useState({
    PhoneNumber: "",
    typeOfBusiness: "",
    businessName: "",
  });

  // ===========================TEsting==================================================

  const happyInitialData = {
    image: {},
    startTime: "",
    endTime: "",
    deals: [
      {
        deal: "",
        price: "",
        type: "",
        other: false,
      },
    ],
  };

  const [happyHourInfo, setHappyHourInfo] = useState(
    daysOfWeek.map((day) => ({
      day,
      ...happyInitialData,
    }))
  );

  const handleDealInputChange = (event, dayIndex, dealIndex, key) => {
    console.log(dayIndex, dealIndex);
    const updatedInfo = JSON.parse(JSON.stringify(happyHourInfo));

    updatedInfo[dayIndex].deals[dealIndex][key] = event.target.value;
    console.log(updatedInfo[dayIndex].deals[dealIndex], "updateInfo...");

    if (event.target.value === "other") {
      updatedInfo[dayIndex].deals[dealIndex]["other"] = true;
      updatedInfo[dayIndex].deals[dealIndex][key] = "";
    } else {
      updatedInfo[dayIndex].deals[dealIndex]["other"] = false;
    }
    setHappyHourInfo(updatedInfo);
  };

  const applyToAllHappyHour = () => {
    setApplyToAllDay(true);
    const selectedData = {
      startTime: happyHourInfo[selectedDay]?.startTime,
      endTime: happyHourInfo[selectedDay]?.endTime,
      image: happyHourInfo[selectedDay]?.image,
      deals: happyHourInfo[selectedDay]?.deals,
    };

    const updatedFormData = daysOfWeek.map((day, dayIndex) => ({
      ...happyHourInfo[dayIndex],
      ...selectedData,
    }));

    setHappyHourInfo(updatedFormData);
  };

  console.log(happyHourInfo);

  //===================================================================

  //===============Vender HappyHours FormData============
  // const [happyHourInfo, setHappyHourInfo] = useState([
  //   {
  //     day: "",
  //     startTime: "",
  //     endTime: "",
  //     image: {},
  //     deals: [
  //       {
  //         deal: "",
  //         price: "",
  //         type: "",
  //         other: false,
  //       },
  //     ],
  //   },
  // ]);

  //================vender New Add HappyHoursData
  const addHappyHourEntry = () => {
    setHappyHourInfo([
      ...happyHourInfo,
      {
        day: "",
        startTime: "",
        endTime: "",
        image: {},
        deals: [
          {
            deal: "",
            price: "",
            type: "",
          },
        ],
      },
    ]);
  };

  //////////=========venderLocation==============

  const handleAddress = (place) => {
    setLocation({ address: place?.label });

    geocodeByAddress(place.label)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        city =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          )?.long_name || "";

        // Find the zip code from address components
        zipCode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        return getLatLng(results[0]);
      })
      .then((res) => {
        setLocation((prevLocation) => ({
          ...prevLocation,
          lat: res.lat,
          long: res.lng,
          zipCode: zipCode,
          city: city,
        }));
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };

  // Function to handle changing the input values
  const handleInputChange = (event, index, key) => {
    const updatedInfo = [...happyHourInfo];

    let { name, value } = event.target;

    if (key === "startTime" || key === "endTime") {
      value = convertTo12HourFormat(value);
    }
    updatedInfo[index][key] = value;
    setHappyHourInfo(updatedInfo);
  };

  // Function to handle image changing the input Images
  const handleHappyHoursImage = (event, index, key) => {
    console.log(event.target.files, "event");
    const updatedInfo = [...happyHourInfo];
    updatedInfo[index][key] = event.target.files[0];
    setHappyHourInfo(updatedInfo);
  };
  // Function to add a deal to a specific happy hour entry
  const addDeal = (index) => {
    // const updatedInfo = [...happyHourInfo];
    const updatedInfo = JSON.parse(JSON.stringify(happyHourInfo));
    updatedInfo[index].deals.push({ deal: "", price: "", type: "" });
    setHappyHourInfo(updatedInfo);
  };
  // Function to handle changing the deal input values
  // const handleDealInputChange = (event, entryIndex, dealIndex, key) => {
  //   const updatedInfo = [...happyHourInfo];
  //   updatedInfo[entryIndex].deals[dealIndex][key] = event.target.value;
  //   setHappyHourInfo(updatedInfo);

  //   if (event.target.value === "other") {
  //     updatedInfo[entryIndex].deals[dealIndex]["other"] = true;
  //     updatedInfo[entryIndex].deals[dealIndex][key] = "";
  //   } else {
  //     updatedInfo[entryIndex].deals[dealIndex]["other"] = false;
  //     updatedInfo[entryIndex].deals[dealIndex][key] = event.target.value;
  //   }
  // };

  function handleTextInputChange(event, index, dealIndex) {
    if (!Array.isArray(happyHourInfo)) {
      return;
    }

    const updatedInfo = [...happyHourInfo];
    if (
      updatedInfo[index] &&
      updatedInfo[index].deals &&
      Array.isArray(updatedInfo[index].deals)
    ) {
      updatedInfo[index].deals[dealIndex]["deal"] = event.target.value;
      setHappyHourInfo(updatedInfo);
    } else {
      console.error("Invalid structure of happyHourInfo at index", index);
    }
  }

  const deleteDeal = (index, dealIndex) => {
    if (happyHourInfo[index].deals.length > 1) {
      const updatedItems = [...happyHourInfo];
      updatedItems[index].deals.splice(dealIndex, 1);
      setHappyHourInfo(updatedItems);
    }
  };

  const handleSameAsDay = (day, idx) => {
    console.log(day?.day, "day Data");
    let tempData = [...happyHourInfo];
    let a = tempData.filter((item) => item.day == day?.day);
    if (a.length > 0) {
      console.log(idx);

      tempData[idx].deals = a[0]?.deals;
      tempData[idx].image = a[0]?.image;
      tempData[idx].startTime = a[0]?.startTime;
      tempData[idx].endTime = a[0]?.endTime;
      console.log(tempData, "sameAsDay");
      setHappyHourInfo(tempData);
    } else {
      // setHappyHourInfo(tempData);
    }
  };

  function hendleOnNext() {
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.businessName === "") {
      errorObj.businessName = "This field is required";
      error = true;
    }
    if (formData.number === "") {
      errorObj.number = "This field is required";
      error = true;
    }
    if (formData.typeOfBusiness === "") {
      errorObj.typeOfBusiness = "This field is required";
      error = true;
    }
    if (formData.city === "") {
      errorObj.city = "This field is required";
      error = true;
    }
    if (formData.zipCode === "") {
      errorObj.zipCode = "This field is required";
      error = true;
    }
    if (formData.PhoneNumber === "") {
      errorObj.PhoneNumber = "This field is required";
      error = true;
    }
    if (formData.PhoneNumber.length < 6) {
      errorObj.PhoneNumber = "Number will be greater than 6";
      error = true;
    }
    if (formData.PhoneNumber.length > 15) {
      errorObj.PhoneNumber = "Number will be less than 15";
      error = true;
    }
    if (formData.startTime === "") {
      errorObj.startTime = "This field is required";
      error = true;
    }
    if (formData.endTime === "") {
      errorObj.endTime = "This field is required";
      error = true;
    }

    const isAnyOpratingHourFormFilled = opratingHour.some(
      (item) => item.startTime || item.endTime
    );

    if (!isAnyOpratingHourFormFilled) {
      errorObj.startTime = "This field is required";
      errorObj.endTime = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      setShow(1);
      scrollToTop();
    }
  }

  useEffect(() => {
    const handleUpload = async () => {
      if (!happyHourInfo || happyHourInfo.length === 0) {
        console.error("No happy hour info provided");
        return;
      }

      const uploadPromises = happyHourInfo.map(async (item, i) => {
        const file = new File([item.image], new Date().getTime());
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: file?.name,
          Body: file,
        };

        try {
          const data = await s3.upload(params).promise();

          return {
            image: data.key,
            day: item.day,
            startTime: item.startTime,
            endTime: item.endTime,
            deals:
              item?.deals?.map((dealItem) => ({
                deal: dealItem.deal,
                price: dealItem.price,
                type: dealItem.type,
              })) || [],
          };
        } catch (error) {
          console.error("Upload failed:", error);
          return null;
        }
      });

      try {
        const uploadedImages = await Promise.all(uploadPromises);
        setSelectImage(uploadedImages);
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    };

    handleUpload();
  }, [happyHourInfo]);

  //==============ApiCall============
  async function onSubmit(e) {
    e.preventDefault();
    //==============Aws s3================

    setLoader(true);

    const res = await addVendorDetails(
      formData,
      selectImage,
      opratingHour,
      userId,
      location,
      phone
    )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Add Successful", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          setEventModal(true);
        }
      })
      .catch((error) => {
        toast.error(`❌${error?.response?.data?.data}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      });
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Add Vendor Details" motherMenu="Vendor" />
      <div className="col-lg-12">
        {loader ? (
          <div className="">
            <Lottie
              style={{ height: "200px", marginTop: "45px", padding: "30px" }}
              animationData={animationData}
            />
          </div>
        ) : (
          <div className="authincation-content text-black p-5">
            <div className="mb-4">
              <h3 className="mb-1 font-w600 text-black ">Vendor Details</h3>
            </div>
            <form onSubmit={onSubmit}>
              {show === 0 ? (
                <div>
                  <div className="form-group">
                    <label className="text-black font-w500">
                      Business Type
                    </label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        name="typeOfBusiness"
                        value={formData.typeOfBusiness}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            typeOfBusiness: event.target.value,
                          })
                        }
                        required
                      >
                        <option hidden>Select..</option>
                        <option value="Bar">Bar</option>
                        <option value="Club">Club</option>
                        <option value="Comedy Club">Comedy Club</option>
                        <option value="Restaurant">Restaurant</option>
                        <option value="Brewery">Brewery</option>
                        <option value="Festival">Festival</option>
                        <option value="Speakeasy">Speakeasy</option>
                        <option value="Sports Bar">Sports Bar</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    {errors.typeOfBusiness && (
                      <div className="text-danger fs-12">
                        {errors.typeOfBusiness}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">Name of Business</label>
                    <input
                      type="text"
                      className="form-control"
                      name="businessName"
                      value={formData.businessName}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          businessName: event.target.value,
                        })
                      }
                      placeholder="Enter Name of Business"
                    />
                    {errors.businessName && (
                      <div className="text-danger fs-12">
                        {errors.businessName}
                      </div>
                    )}
                  </div>
                  <div className="form-group auto-address">
                    <label className="mb-2 ">Address</label>
                    <GooglePlacesAutocomplete
                      className="form-control"
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      selectProps={{
                        placeholder: "Enter Address",
                        // name: "address",
                        onChange: (place) => {
                          handleAddress(place);
                          console.log(JSON.stringify(place), "hcfgvjb");
                        },
                      }}
                    />
                    {errors.address && (
                      <div className="text-danger fs-12">{errors.address}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={location.city}
                      onChange={(event) =>
                        setLocation({ ...location, city: event.target.value })
                      }
                      placeholder="Enter City"
                    />
                    {errors.city && (
                      <div className="text-danger fs-12">{errors.city}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="zipCode"
                      value={location.zipCode}
                      onChange={(event) =>
                        setLocation({
                          ...location,
                          zipCode: event.target.value,
                        })
                      }
                      placeholder="Enter Zip Code"
                    />
                    {errors.zipCode && (
                      <div className="text-danger fs-12">{errors.zipCode}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">Phone Number</label>

                    <div className="contact-name d-flex">
                      <PhoneInput
                        className="p-0 conuntry-code"
                        country={"us"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(`+${phone}`)}
                      />
                      <input
                        type="number"
                        className="form-control"
                        value={formData.PhoneNumber}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            PhoneNumber: event.target.value,
                          })
                        }
                        placeholder="Enter Phone Number"
                      />
                    </div>
                    {errors.PhoneNumber && (
                      <div className="text-danger fs-12">
                        {errors.PhoneNumber}
                      </div>
                    )}
                  </div>

                  <div className="form-group ">
                    <h4 className="mb-5 mt-3">Operating Hours</h4>
                    <div className="d-flex justify-content-end mb-3">
                      <button
                        type="button"
                        className="btn fs-12 py-2 px-3 applyBtn"
                        onClick={applyToAll}
                      >
                        Apply to All
                      </button>
                    </div>
                    <label className="mb-2 mt-2">Day(s)</label>
                    <div
                      className="d-flex align-items-center mb-2"
                      style={{ gap: "10px" }}
                    >
                      {daysOfWeek.map((day, dayIndex) => (
                        <div key={dayIndex}>
                          <p
                            className={dayIndex === selectedDay ? "days" : " "}
                            onClick={() => toggleDayForm(dayIndex)}
                            style={{ cursor: "pointer" }}
                          >
                            {day}
                          </p>
                        </div>
                      ))}
                    </div>
                    {daysOfWeek.map((day, dayIndex) => (
                      <div key={dayIndex}>
                        {selectedDay === dayIndex && (
                          <div>
                            <div className="form-group d-flex mt-2">
                              <div className="w-100 mr-2">
                                <label className="mb-2 ">Start Time</label>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="startTime"
                                  defaultValue={convertTo24HourFormat(
                                    opratingHour[dayIndex]?.startTime
                                  )}
                                  onChange={(e) =>
                                    handleOpratingInputChange(
                                      e,
                                      dayIndex,
                                      "startTime"
                                    )
                                  }
                                />

                                {errors.startTime && (
                                  <div className="text-danger fs-12">
                                    {errors.startTime}
                                  </div>
                                )}
                              </div>
                              <div className="w-100">
                                <label className="mb-2 ">End Time</label>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="endTime"
                                  defaultValue={convertTo24HourFormat(
                                    opratingHour[dayIndex]?.endTime
                                  )}
                                  onChange={(e) =>
                                    handleOpratingInputChange(
                                      e,
                                      dayIndex,
                                      "endTime"
                                    )
                                  }
                                />
                                {errors.endTime && (
                                  <div className="text-danger fs-12">
                                    {errors.endTime}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className="d-flex align-items-center m-0"
                              style={{ gap: "6px" }}
                            >
                              <input
                                className="mb-4"
                                type="checkbox"
                                checked={opratingHour[dayIndex]?.status}
                                onChange={(e) =>
                                  handleOpratingcheck(e, dayIndex, "status")
                                }
                              />
                              <label className="m-0 mb-3">Closed</label>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-sm"
                        style={{
                          backgroundColor: "#F36C03",
                          border: "none",
                          color: "white",
                        }}
                        onClick={() => hendleOnNext()}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-start">
                    <button
                      className="btn btn-sm"
                      style={{
                        backgroundColor: "#F36C03",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => setShow(0)}
                    >
                      Prev
                    </button>
                  </div>
                  <h4 className="my-4 text-black">Happy Hour Info </h4>
                  {/* <div className="d-flex justify-content-end mb-3">
                    <Button
                      className="btn fs-12 py-2 px-3"
                      style={{ backgroundColor: "#F36C03", border: "none" }}
                      onClick={addHappyHourEntry}
                    >
                      + Add
                    </Button>
                  </div> */}

                  {/* {happyHourInfo.map((entry, index) => (
                    <div key={index}>
                      <div className="form-group">
                        <label className="mb-2 ">Day(s)</label>
                        <div className="contact-name">
                          <select
                            className="form-control"
                            required
                            value={entry.day}
                            onChange={(e) => handleInputChange(e, index, "day")}
                          >
                            <option hidden>Select..</option>
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option>
                          </select>
                        </div>
                        {errors.day && (
                          <div className="text-danger fs-12">{errors.day}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-2 ">Image</label>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          className="form-control"
                          multiple
                          // required
                          onChange={(e) =>
                            handleHappyHoursImage(e, index, "image")
                          }
                        />
                        <div>
                          <p
                            className="ml-1 text-warning"
                          >
                            Upload file: {happyHourInfo[index]?.image?.name}
                          </p>
                        </div>
                      </div>
                      <div className="form-group d-flex">
                        <div className="w-100 mr-2">
                          <label className="mb-2 ">Start Time</label>
                          <input
                            type="time"
                            className="form-control"
                            required
                            defaultValue={convertTo24HourFormat(
                              happyHourInfo[index]?.startTime
                            )}
                            onChange={(e) =>
                              handleInputChange(e, index, "startTime")
                            }
                          />
                          {errors.happyStartTime && (
                            <div className="text-danger fs-12">
                              {errors.happyStartTime}
                            </div>
                          )}
                        </div>
                        <div className="w-100">
                          <label className="mb-2 ">End Time</label>
                          <input
                            type="Time"
                            className="form-control"
                            required
                            defaultValue={convertTo24HourFormat(
                              happyHourInfo[index]?.endTime
                            )}
                            onChange={(e) =>
                              handleInputChange(e, index, "endTime")
                            }
                          />
                          {errors.happyEndTime && (
                            <div className="text-danger fs-12">
                              {errors.happyEndTime}
                            </div>
                          )}
                        </div>
                      </div>

                      {entry.deals.map((deal, dealIndex) => (
                        <div className="form-group d-flex" key={dealIndex}>
                          <div className="w-100 mr-2">
                            <label className="mb-3 ">Deal</label>

                            {errors.deal && (
                              <div className="text-danger fs-12">
                                {errors.deal}
                              </div>
                            )}
                            <div className="d-flex  w-100">
                              <input
                                type="text"
                                disabled={deal.other ? false : true}
                                className="form-control deal-input"
                                placeholder={
                                  deal.other ? "Enter custom deal" : "Select.."
                                }
                                required="required"
                                value={deal.deal}
                                onChange={(e) =>
                                  handleTextInputChange(e, index, dealIndex)
                                }
                              />
                              <div className="contact-name">
                                <select
                                  className={"form-control select-deal"}
                                  value={deal.deal}
                                  onChange={(e) =>
                                    handleDealInputChange(
                                      e,
                                      index,
                                      dealIndex,
                                      "deal"
                                    )
                                  }
                                  required
                                >
                                  <option hidden>Select..</option>
                                  <option value="Wine">Wine</option>
                                  <option value="Shots">Shots</option>
                                  <option value="Margarita">Margarita</option>
                                  <option value="Well Cocktails">
                                    Well Cocktails
                                  </option>
                                  <option value="Beer Pitcher">
                                    Beer Pitcher
                                  </option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <label className="mb-3 ">Price</label>
                              {happyHourInfo[index]?.deals?.length > 1 && (
                                <button
                                  type="button"
                                  className=" delete-bTn"
                                  onClick={() => deleteDeal(index, dealIndex)}
                                >
                                  X
                                </button>
                              )}
                            </div>
                            <div className="d-flex form-control ">
                              <input
                                type="number"
                                required
                                className="border-0 w-100"
                                value={deal.price}
                                onChange={(e) =>
                                  handleDealInputChange(
                                    e,
                                    index,
                                    dealIndex,
                                    "price"
                                  )
                                }
                              />
                              <select
                                className="border-0"
                                value={deal.type}
                                onChange={(e) =>
                                  handleDealInputChange(
                                    e,
                                    index,
                                    dealIndex,
                                    "type"
                                  )
                                }
                                required
                              >
                                <option hidden></option>
                                <option value="Fixed">$</option>
                                <option value="value">$ Off</option>
                                <option value="percentage">% Off</option>
                                <option value="2 for 1">2 for 1</option>
                              </select>
                            </div>

                            {errors.price && (
                              <div className="text-danger fs-12">
                                {errors.price}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <h6
                        className="my-4 text-orange"
                        onClick={() => addDeal(index)}
                        style={{ cursor: "pointer", width: "200px" }}
                      >
                        Add New Item
                      </h6>

                      {index > 0 ? (
                        <>
                          <div className="form-group ">
                            <div className="">
                              <input
                                type="checkbox"
                                className="mr-2"
                                value={sameAs}
                                onChange={(e) => setSameAs(e.target.checked)}
                              />
                              <label className="mb-2 ">Same As </label>
                            </div>
                          </div>
                          {sameAs && (
                            <div>
                              {happyHourInfo.map(
                                (item, i) =>
                                  i < happyHourInfo.length - 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-warning py-2 px-2 mb-3 ml-2"
                                      key={i}
                                      onClick={() =>
                                        handleSameAsDay(item, index)
                                      }
                                    >
                                      {item.day}
                                    </button>
                                  )
                              )}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  ))} */}
                  <div className="d-flex justify-content-end mb-3">
                    <button
                      type="button"
                      className="btn fs-12 py-2 px-3 applyBtn"
                      onClick={applyToAllHappyHour}
                    >
                      Apply to All
                    </button>
                  </div>
                  <label className="mb-2 mt-2">Day(s)</label>
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ gap: "10px" }}
                  >
                    {daysOfWeek.map((day, dayIndex) => (
                      <div key={dayIndex}>
                        <p
                          className={dayIndex === selectedDay ? "days" : " "}
                          onClick={() => toggleDayForm(dayIndex)}
                          style={{ cursor: "pointer" }}
                        >
                          {day}
                        </p>
                      </div>
                    ))}
                  </div>
                  {daysOfWeek.map((day, dayIndex) => (
                    <div key={dayIndex}>
                      {selectedDay === dayIndex && (
                        <div>
                          <div className="form-group">
                            <label className="mb-2 ">Image</label>
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              className="form-control"
                              multiple
                              onChange={(e) =>
                                handleHappyHoursImage(e, dayIndex, "image")
                              }
                            />
                            <div>
                              <p className="ml-1 text-warning">
                                Upload file:{" "}
                                {happyHourInfo[dayIndex]?.image?.name}
                              </p>
                            </div>
                          </div>
                          <div className="form-group d-flex">
                            <div className="w-100 mr-2">
                              <label className="mb-2 ">Start Time</label>
                              <input
                                type="time"
                                className="form-control"
                                defaultValue={convertTo24HourFormat(
                                  happyHourInfo[dayIndex]?.startTime
                                )}
                                onChange={(e) =>
                                  handleInputChange(e, dayIndex, "startTime")
                                }
                              />
                              {errors.happyStartTime && (
                                <div className="text-danger fs-12">
                                  {errors.happyStartTime}
                                </div>
                              )}
                            </div>
                            <div className="w-100">
                              <label className="mb-2 ">End Time</label>
                              <input
                                type="time"
                                className="form-control"
                                defaultValue={convertTo24HourFormat(
                                  happyHourInfo[dayIndex]?.endTime
                                )}
                                onChange={(e) =>
                                  handleInputChange(e, dayIndex, "endTime")
                                }
                              />
                              {errors.happyEndTime && (
                                <div className="text-danger fs-12">
                                  {errors.happyEndTime}
                                </div>
                              )}
                            </div>
                          </div>
                          {happyHourInfo[dayIndex]?.deals?.map(
                            (deal, dealIndex) => (
                              <div
                                className="form-group d-flex"
                                key={dealIndex}
                              >
                                <div className="w-100 mr-2">
                                  <label className="mb-3 ">Deal</label>

                                  {errors.deal && (
                                    <div className="text-danger fs-12">
                                      {errors.deal}
                                    </div>
                                  )}
                                  <div className="d-flex  w-100">
                                    <input
                                      type="text"
                                      disabled={deal.other ? false : true}
                                      className="form-control deal-input"
                                      placeholder={
                                        deal.other
                                          ? "Enter custom deal"
                                          : "Select.."
                                      }
                                      required="required"
                                      value={deal.deal}
                                      onChange={(e) =>
                                        handleTextInputChange(
                                          e,
                                          dayIndex,
                                          dealIndex
                                        )
                                      }
                                    />
                                    <div className="contact-name">
                                      <select
                                        className={"form-control select-deal"}
                                        value={deal.deal}
                                        onChange={(e) =>
                                          handleDealInputChange(
                                            e,
                                            dayIndex,
                                            dealIndex,
                                            "deal"
                                          )
                                        }
                                        required
                                      >
                                        <option hidden>Select..</option>
                                        <option value="Wine">Wine</option>
                                        <option value="Shots">Shots</option>
                                        <option value="Margarita">
                                          Margarita
                                        </option>
                                        <option value="Well Cocktails">
                                          Well Cocktails
                                        </option>
                                        <option value="Beer Pitcher">
                                          Beer Pitcher
                                        </option>
                                        <option value="other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="w-100">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label className="mb-3 ">Price</label>
                                    {happyHourInfo[dayIndex]?.deals?.length >
                                      1 && (
                                      <button
                                        type="button"
                                        className=" delete-bTn"
                                        onClick={() =>
                                          deleteDeal(dayIndex, dealIndex)
                                        }
                                      >
                                        X
                                      </button>
                                    )}
                                  </div>
                                  <div className="d-flex form-control ">
                                    <input
                                      type="number"
                                      required
                                      className="border-0 w-100"
                                      value={deal.price}
                                      onChange={(e) =>
                                        handleDealInputChange(
                                          e,
                                          dayIndex,
                                          dealIndex,
                                          "price"
                                        )
                                      }
                                    />
                                    <select
                                      className="border-0"
                                      value={deal.type}
                                      onChange={(e) =>
                                        handleDealInputChange(
                                          e,
                                          dayIndex,
                                          dealIndex,
                                          "type"
                                        )
                                      }
                                      required
                                    >
                                      <option hidden></option>
                                      <option value="Fixed">$</option>
                                      <option value="value">$ Off</option>
                                      <option value="percentage">% Off</option>
                                      <option value="2 for 1">2 for 1</option>
                                    </select>
                                  </div>

                                  {errors.price && (
                                    <div className="text-danger fs-12">
                                      {errors.price}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                          <h6
                            className="my-4 text-orange"
                            onClick={() => addDeal(dayIndex)}
                            style={{ cursor: "pointer", width: "200px" }}
                          >
                            Add New Item
                          </h6>
                        </div>
                      )}
                    </div>
                  ))}

                  <div className="text-right">
                    <button
                      type="submit"
                      className="btn text-white  mr-3"
                      style={{ backgroundColor: "#F36C03", border: "none" }}
                      // onClick={() => setEventModal(true)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
      <AddEvent
        show={eventModal}
        props={props}
        onHide={() => setEventModal(false)}
      />
    </div>
    // )}
    // </>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.UserData.userId,
  };
};
export default connect(mapStateToProps)(AddVenderDetails);
