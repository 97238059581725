import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import AWS, { ServiceCatalogAppRegistry } from "aws-sdk";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import dayjs from "dayjs";
import {
  editHappyHoursApi,
  deleteHappyHourDealApi,
  getHappyHourDetailsApi,
} from "../components/APIs/Api";

const formatTime = (input) => {
  if (dayjs(input).isValid()) {
    return dayjs(input).format("hh:mm A");
  } else {
    return input;
  }
};

const endTimeFormatted = (input) => {
  if (dayjs(input).isValid()) {
    return dayjs(input).format("hh:mm A");
  } else {
    return input;
  }
};

export default function EditHappyHours(props) {
  const { state } = props.location;
  const [data, setData] = useState([]);

  const [formattedTime, setFormattedTime] = useState(
    formatTime(data?.startTime)
  );
  const [endTimeFormat, setEndTimeFormat] = useState(
    endTimeFormatted(data?.endTime)
  );
  const s3 = new AWS.S3();
  const [image, setImage] = useState(data?.image);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newSelectedDeal, setNewSelectedDeal] = useState("");
  const [selectImage, setSelectImage] = useState();
  const [happyHourInfo, setHappyHourInfo] = useState({
    day: "",
    startTime: "",
    endTime: "",
    deals: [],
  });
  const [dealsToDelete, setDealsToDelete] = useState([]);

  async function getHappyHourDetails() {
    setIsLoading(true);
    try {
      const response = await getHappyHourDetailsApi(state?._id);
      setData(response?.data?.data);
      setFormattedTime(formatTime(response?.data?.data?.startTime));
      setEndTimeFormat(endTimeFormatted(response?.data?.data?.endTime));
      setHappyHourInfo({
        day: response?.data?.data?.day,
        startTime: formatTime(response?.data?.data?.startTime),
        endTime: endTimeFormatted(response?.data?.data?.endTime),
        deals: response?.data?.data?.deals?.map((dealItem) => ({
          deal: dealItem?.deal,
          price: dealItem?.price,
          type: dealItem?.type,
          id: dealItem?._id,
          other: false,
        })),
      });
      setImage(response?.data?.data?.image);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setIsLoading(false);
    }
  }

  const convertTo12HourFormat = (time24hr) => {
    const [hour, minute] = time24hr.split(":");
    const amPm = parseInt(hour, 10) >= 12 ? "PM" : "AM";
    const hour12 = parseInt(hour, 10) % 12 || 12;
    return `${hour12}:${minute} ${amPm}`;
  };

  const convertTo24HourFormat = (time12hr) => {
    if (!time12hr) {
      return "";
    }

    const parts = time12hr.split(" ");

    if (parts.length !== 2) {
      return "";
    }

    const [time, period] = parts;
    const [hour, minute] = time.split(":");

    if (hour === undefined || minute === undefined) {
      return "";
    }

    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 !== 12) {
      hour24 += 12;
    } else if (period === "AM" && hour24 === 12) {
      hour24 = 0;
    }

    return `${hour24.toString().padStart(2, "0")}:${minute}`;
  };

  const handleInputChange = (event, key) => {
    const { value } = event.target;
    if (key === "day") {
      setHappyHourInfo((prevInfo) => ({
        ...prevInfo,
        [key]: value,
      }));
    } else {
      const updatedInfo = { ...happyHourInfo };

      if (key === "startTime" || key === "endTime") {
        const formattedValue = convertTo12HourFormat(value);
        updatedInfo[key] = formattedValue;
      } else {
        updatedInfo[key] = value;
      }
      setHappyHourInfo(updatedInfo);
    }
  };

  const handleDealInputChange = (event, dealIndex, key) => {
    const updatedInfo = { ...happyHourInfo };
    if (event.target.value === "other") {
      updatedInfo.deals[dealIndex]["other"] = true;
      updatedInfo.deals[dealIndex][key] = "";
    } else {
      updatedInfo.deals[dealIndex]["other"] = false;
      updatedInfo.deals[dealIndex][key] = event.target.value;
    }

    setHappyHourInfo(updatedInfo);
  };

  const handleDeleteDeal = (dealId) => {
    setDealsToDelete((prevDealsToDelete) => [...prevDealsToDelete, dealId]);
    setHappyHourInfo((prevInfo) => ({
      ...prevInfo,
      deals: prevInfo.deals.filter((deal) => deal.id !== dealId),
    }));
  };

  const addDeal = (index) => {
    const updatedInfo = JSON.parse(JSON.stringify(happyHourInfo));
    updatedInfo.deals.push({ deal: "", price: "", type: "" });
    setHappyHourInfo(updatedInfo);
  };
  function handleTextInputChange(event, dealIndex) {
    const updatedInfo = { ...happyHourInfo };
    updatedInfo.deals[dealIndex]["deal"] = event.target.value;
    setHappyHourInfo(updatedInfo);
  }

  useEffect(() => {
    const handleUpload = async () => {
      if (!image) {
        console.error("No file selected");
        return;
      }
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: image?.name,
        Body: image,
      };
      try {
        const data = await s3.upload(params).promise();
        setSelectImage(data);
      } catch (error) {
        console.error("Upload failed:", error);
      }
    };

    handleUpload();
  }, [image]);

  useEffect(() => {
    if (state?._id) {
      getHappyHourDetails();
    }
  }, [state._id]);

  async function onSubmit(e) {
    e.preventDefault();
    setLoader(true);

    // Delete marked deals
    for (const dealId of dealsToDelete) {
      await deleteHappyHourDealApi(dealId, data?._id).catch((error) => {
        toast.error(error?.response?.data?.data);
      });
    }

    const res = await editHappyHoursApi(
      happyHourInfo,
      selectImage?.key ? selectImage?.key : image,
      data?._id
    )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          props.history.goBack();
        }
      })
      .catch((error) => {
        toast.error(`❌${error?.response?.data?.data}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      });
  }

  return (
    <div>
      {isLoading ? (
        <div className="">
          <Lottie
            style={{
              height: "200px",
              marginTop: "45px",
              padding: "30px",
            }}
            animationData={animationData}
          />
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <h4 className="my-4 text-black">Happy Hour Info</h4>

          <div className="form-group">
            <label className="mb-2 ">Day(s)</label>
            <div className="contact-name">
              <select
                className="form-control"
                required="required"
                value={happyHourInfo.day}
                onChange={(e) => handleInputChange(e, "day")}
              >
                <option hidden>Select..</option>
                <option>Monday</option>
                <option>Tuesday</option>
                <option>Wednesday</option>
                <option>Thursday</option>
                <option>Friday</option>
                <option>Saturday</option>
                <option>Sunday</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-2 ">Image</label>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              className="form-control"
              multiple
              onChange={(e) => setImage(e.target.files[0])}
            />
            <div>
              <p className="ml-1 text-gray">
                Upload file: {image?.name ? image?.name : image}
              </p>
            </div>
          </div>
          <div className="form-group d-flex">
            <div className="w-100 mr-2">
              <label className="mb-2 ">Start Time</label>
              <input
                type="time"
                className="form-control"
                defaultValue={convertTo24HourFormat(formattedTime)}
                onChange={(e) => handleInputChange(e, "startTime")}
              />
            </div>
            <div className="w-100">
              <label className="mb-2 ">End Time</label>
              <input
                type="time"
                className="form-control"
                defaultValue={convertTo24HourFormat(endTimeFormat)}
                onChange={(e) => handleInputChange(e, "endTime")}
              />
            </div>
          </div>
          {happyHourInfo?.deals?.map((item, dealIndex) => (
            <div className="form-group d-flex" key={dealIndex}>
              <div className="w-100 mr-2 ">
                <label className="mb-3 ">Deal</label>
                <div className="d-flex  w-100">
                  <input
                    type="text"
                    disabled={item.other ? false : true}
                    className="form-control deal-input"
                    placeholder="Enter custom deal"
                    value={item.deal}
                    onChange={(e) => {
                      setNewSelectedDeal(e.target.value);
                      handleTextInputChange(e, dealIndex);
                    }}
                  />
                  <div className="contact-name">
                    <select
                      className={"form-control select-deal"}
                      onChange={(e) =>
                        handleDealInputChange(e, dealIndex, "deal")
                      }
                      required
                    >
                      <option hidden>Select..</option>
                      <option value="Wine">Wine</option>
                      <option value="Shots">Shots</option>
                      <option value="Margarita">Margarita</option>
                      <option value="Well Cocktails">Well Cocktails</option>
                      <option value="Beer Pitcher">Beer Pitcher</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="mb-3 ">Price</label>
                  <button
                    type="button"
                    className=" delete-bTn"
                    onClick={() => handleDeleteDeal(item?.id)}
                  >
                    X
                  </button>
                </div>
                <div className="d-flex form-control ">
                  <input
                    type="number"
                    className="border-0 w-100"
                    value={item.price}
                    onChange={(e) =>
                      handleDealInputChange(e, dealIndex, "price")
                    }
                  />
                  <select
                    className="border-0"
                    value={item.type}
                    onChange={(e) =>
                      handleDealInputChange(e, dealIndex, "type")
                    }
                    required
                  >
                    <option hidden></option>
                    <option value="Fixed">$</option>
                    <option value="value">$ Off</option>
                    <option value="percentage">% Off</option>
                    <option value="2 for 1">2 for 1</option>
                  </select>
                </div>
              </div>
            </div>
          ))}
          <h6
            className="my-4 text-orange"
            onClick={() => addDeal()}
            style={{ cursor: "pointer", width: "200px" }}
          >
            Add New Item
          </h6>

          <div className="text-right">
            <button
              className={`bTon ${loader ? "disabled" : ""}`}
              disabled={loader}
              type="submit"
              style={
                loader
                  ? { pointerEvents: "none", opacity: 0.5 }
                  : { pointerEvents: "auto" }
              }
            >
              {loader ? (
                <div
                  className="d-flex align-items-center justify-content-center "
                  style={{ gap: "5px" }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
