import { SET_CURRENT_USER_ACTION } from "../actions/AuthActions";



const initialState = {
  userId: "",
};

export default function UserDetailsReducer(state = initialState, action) {
  if (action.type === SET_CURRENT_USER_ACTION) {
    return {
      ...state,
      userId: action.payload,
    };
  }
  return state;
}