import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/user-icon3.png";
// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey, title }) => {
  const [imageData, setImageData] = useState(null);

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };
  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);

  if (!imageData) {
    return (
      <div>
        {title === "details" ? (
          <img
            style={{
              height: "132px",
              width: "132px",
              objectFit: "cover",
            }}
            src={defaultImage}
          />
        ) : (
          <img height={50} width={50} src={defaultImage} />
        )}
      </div>
    );
  }

  return (
    <div>
      {title === "details" ? (
        <img
          style={{
            height: "132px",
            width: "132px",
            objectFit: "cover",
          }}
          className="text-center profile-img"
          src={`data:image/png;base64,${imageData}`}
          alt="S3 Content"
          onError={onImageError}
        />
      ) : (
        <img
          width={50}
          height={50}
          style={{ borderRadius: "15px" }}
          className="text-center"
          src={`data:image/png;base64,${imageData}`}
          alt="S3 Content"
          onError={onImageError}
        />
      )}
    </div>
  );
};

export default S3Image;
