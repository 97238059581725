import React, { useState } from "react";
import "./NavBar.css"

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/wuts-logo.svg";
import logoText from "../../../images/Barat.svg";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="nav-header" style={{ zIndex: "12" }}>
      <Link to="/" className="brand-logo">
        {toggle ? (
          <img height={60}width={60} className="logo-abbr" src={logo} alt="" />
    // <div>New</div>
        ) : (
          //  <img className="logo-compact" src={logoText} alt="" />
          <img height={80}width={100} className="brand-title" src={logo} alt="" />
        )}
      </Link>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger manubar ${toggle ? "is-active" : ""}`}>
          <span  className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
