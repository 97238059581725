import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { variable } from "../components/Variable";
import { ToastContainer, toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { editVendorDetailsApi } from "../components/APIs/Api";
import PhoneInput from "react-phone-input-2";
import { uploadFile } from "react-s3";
import "react-phone-input-2/lib/style.css";
import AWS from "aws-sdk";

// import { editServiceApi } from "../components/APIs/Api";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
///===========s3 Config================

export default function EditServiceProvider(props) {
  const item = props.location?.state?.item;
  console.log(item, "updated");

  const s3 = new AWS.S3();

  const [selectedDay, setSelectedDay] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showGooglePlaces, setShowGooglePlaces] = useState(false);
  const [image, setImage] = useState();
  const [phone, setPhone] = useState(item?.countryCode);
  const [selectImage, setSelectImage] = useState();
  const [formData, setFormData] = useState({
    userId: item?._id,
    PhoneNumber: item?.phoneNumber,
    typeOfBusiness: item?.typeOfBusiness,
    businessName: item?.businessName,
    about: item?.about,
    websiteUrl: item?.websiteUrl,
  });

  // const [opratingHour, setOpratingHour] = useState(
  //   daysOfWeek.map((day, index) => item?.operatingHours[index])
  // );
  const [opratingHour, setOpratingHour] = useState(() =>
    daysOfWeek.map((day, index) => ({
      day,
      startTime: item?.operatingHours?.[index]?.startTime || "",
      endTime: item?.operatingHours?.[index]?.endTime || "",
      status: item?.operatingHours?.[index]?.status || false,
    }))
  );

  const [location, setLocation] = useState({
    address: item?.address,
    lat: item?.location?.coordinates[1],
    long: item?.location?.coordinates[0],
    zipCode: item?.zipCode,
    city: item?.city,
  });
  let zipCode = "";
  let city = "";

  console.log(location);

  // console.log(item);
  const handleClick = () => {
    setShowGooglePlaces(true);
  };

  //============convert Time in 12hr=============================
  const convertTo12HourFormat = (time24hr) => {
    const [hour, minute] = time24hr.split(":");
    const amPm = parseInt(hour, 10) >= 12 ? "PM" : "AM";
    const hour12 = parseInt(hour, 10) % 12 || 12;
    return `${hour12}:${minute} ${amPm}`;
  };
  //=================convert time in 24hr=================
  const convertTo24HourFormat = (time12hr) => {
    if (!time12hr) {
      return "";
    }

    const parts = time12hr.split(" ");

    if (parts.length !== 2) {
      return "";
    }

    const [time, period] = parts;
    const [hour, minute] = time.split(":");

    if (hour === undefined || minute === undefined) {
      return ""; // Handle invalid time format
    }

    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 !== 12) {
      hour24 += 12;
    } else if (period === "AM" && hour24 === 12) {
      hour24 = 0;
    }

    return `${hour24.toString().padStart(2, "0")}:${minute}`;
  };

  //====================onChange function in Opreting hr================
  const handleOpratingInputChange = (e, dayIndex, key) => {
    let { value } = e.target;

    if (key === "startTime" || key === "endTime") {
      value = convertTo12HourFormat(value);
    }

    const updatedFormData = [...opratingHour];
    updatedFormData[dayIndex][key] = value;
    setOpratingHour(updatedFormData);
  };
  const handleAddress = (place) => {
    setLocation({ address: place?.label });

    geocodeByAddress(place.label)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        city =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          )?.long_name || "";

        // Find the zip code from address components
        zipCode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        return getLatLng(results[0]);
      })
      .then((res) => {
        setLocation((prevLocation) => ({
          ...prevLocation,
          lat: res.lat,
          long: res.lng,
          zipCode: zipCode,
          city: city,
        }));
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };
  //======================Apply to all function =====================
  const applyToAll = () => {
    const selectedData = {
      startTime: opratingHour[selectedDay]?.startTime,
      endTime: opratingHour[selectedDay]?.endTime,
      status: opratingHour[selectedDay]?.status,
    };

    const updatedFormData = opratingHour.map((data) => ({
      ...data,
      ...selectedData,
    }));
    setOpratingHour(updatedFormData);
  };

  const toggleDayForm = (dayIndex) => {
    setSelectedDay(dayIndex);
  };

  //==============oprating day open and close ========================
  const handleOpratingcheck = (event, dayIndex, key) => {
    const updatedInfo = [...opratingHour];
    updatedInfo[dayIndex][key] = event.target.checked;
    setOpratingHour(updatedInfo);
  };

  //======================image Upload =================
  useEffect(() => {
    const handleUpload = async () => {
      if (!image) {
        console.error("No file selected");
        return;
      }

      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: image?.name,
        Body: image,
      };

      try {
        const data = await s3.upload(params).promise();
        setSelectImage(data);
      } catch (error) {
        console.error("Upload failed:", error);
      }
    };

    handleUpload();
  }, [image]);
  //Add Submit data

  async function handleAddFormSubmit(e) {
    e.preventDefault();
    setLoader(true);
    // const file = new File([image], new Date().getTime());
    // if (file.size > 0) {
    //   resultImage = await uploadFile(file, config);
    // }
    const response = await editVendorDetailsApi(
      formData,
      opratingHour,
      location,
      selectImage?.key ? selectImage?.key : item?.coverImage,
      phone
    )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Add Successful", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(`❌${error?.response?.data?.data}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  return (
    <div>
      <h3 className="text-start mb-3">Edit Vendor Details</h3>
      <form onSubmit={handleAddFormSubmit}>
        <div className="form-group mt-4">
          <label className="text-black font-w500">Cover Image</label>
          <div className="mb-3">
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {!image?.name?.length > 0 && (
              <div>
                <p className="ml-1 text-gray">
                  Upload file: {item?.coverImage}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="form-group mt-2">
          <label className="font-w500">Business Type</label>
          <div className="contact-name">
            <select
              className="form-control"
              name="typeOfBusiness"
              value={formData.typeOfBusiness}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  typeOfBusiness: event.target.value,
                })
              }
              required
            >
              <option hidden>Select..</option>
              <option value="Bar">Bar</option>
              <option value="Club">Club</option>
              <option value="Comedy Club">Comedy Club</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Brewery">Brewery</option>
              <option value="Festival">Festival</option>
              <option value="Speakeasy">Speakeasy</option>
              <option value="Sports Bar">Sports Bar</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="mb-2 ">Name of Business</label>
          <input
            type="text"
            className="form-control"
            name="businessName"
            value={formData.businessName}
            onChange={(event) =>
              setFormData({
                ...formData,
                businessName: event.target.value,
              })
            }
            placeholder="Enter Name of Business"
          />
        </div>

        <div className="form-group auto-address">
          <label className="mb-2 ">Address</label>
          {showGooglePlaces ? (
            <GooglePlacesAutocomplete
              className="form-control"
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              selectProps={{
                placeholder: "Enter Address",
                onChange: (place) => {
                  handleAddress(place);
                },
                renderInput: (props) => (
                  <>
                    {/* Display the selected address */}
                    <input
                      {...props}
                      defaultValue={location?.address}
                      onChange={(e) => {
                        setLocation({ ...location, address: e.target.value });
                      }}
                      placeholder="Enter Address"
                    />
                  </>
                ),
              }}
              value={location.address}
            />
          ) : (
            <input
              type="text"
              className="form-control mt-2"
              value={location.address}
              onClick={handleClick}
              onChange={(event) =>
                setLocation({ ...location, address: event.target.value })
              }
              placeholder="Display Address"
            />
          )}
        </div>

        <div className="form-group">
          <label className="mb-2 ">City</label>
          <input
            type="text"
            className="form-control"
            name="city"
            value={location.city}
            onChange={(event) =>
              setLocation({ ...location, city: event.target.value })
            }
            placeholder="Enter City"
          />
        </div>
        <div className="form-group">
          <label className="mb-2 ">Zip Code</label>
          <input
            type="text"
            className="form-control"
            name="zipCode"
            value={location.zipCode}
            onChange={(event) =>
              setLocation({
                ...location,
                zipCode: event.target.value,
              })
            }
            placeholder="Enter Zip Code"
          />
        </div>
        <div className="form-group">
          <label className="mb-2 ">Phone Number</label>

          <div className="contact-name d-flex">
            <PhoneInput
              className="p-0 conuntry-code"
              country={"us"}
              enableSearch={true}
              value={phone}
              onChange={(phone) => setPhone(`${phone}`)}
            />
            <input
              type="number"
              className="form-control"
              value={formData.PhoneNumber}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  PhoneNumber: event.target.value,
                })
              }
              placeholder="Enter Phone Number"
            />
            <span className="validation-text"></span>
          </div>
        </div>
        <div className="form-group">
          <label className="mb-2 ">About</label>
          <textarea
            type="text"
            className="form-control"
            value={formData?.about}
            onChange={(event) =>
              setFormData({
                ...formData,
                about: event.target.value,
              })
            }
            placeholder="Type here.."
          />
        </div>
        <div className="form-group">
          <label className="text-black font-w500">Website URL</label>
          <input
            type="url"
            className="form-control"
            value={formData?.websiteUrl}
            onChange={(event) =>
              setFormData({
                ...formData,
                websiteUrl: event.target.value,
              })
            }
            placeholder="Enter link here"
          />
        </div>

        <div className="form-group ">
          <h4 className="mb-5 mt-3">Operating Hours</h4>
          <div className="d-flex justify-content-end mb-3">
            <button
              type="button"
              className="btn fs-12 py-2 px-3 applyBtn"
              onClick={applyToAll}
            >
              Apply to All
            </button>
          </div>
          <label className="mb-2 mt-2">Day(s)</label>
          <div
            className="d-flex align-items-center mb-2"
            style={{ gap: "10px" }}
          >
            {daysOfWeek.map((day, dayIndex) => (
              <div key={dayIndex}>
                <p
                  className={dayIndex === selectedDay ? "days" : " "}
                  onClick={() => toggleDayForm(dayIndex)}
                  style={{ cursor: "pointer" }}
                >
                  {day}
                </p>
              </div>
            ))}
          </div>
          {daysOfWeek.map((day, dayIndex) => (
            <div key={dayIndex}>
              {selectedDay === dayIndex && (
                <div>
                  <div className="form-group d-flex mt-2">
                    <div className="w-100 mr-2">
                      <label className="mb-2 ">Start Time</label>
                      <input
                        type="time"
                        className="form-control"
                        name="startTime"
                        defaultValue={convertTo24HourFormat(
                          opratingHour[dayIndex]?.startTime
                        )}
                        onChange={(e) =>
                          handleOpratingInputChange(e, dayIndex, "startTime")
                        }
                      />
                    </div>
                    <div className="w-100">
                      <label className="mb-2 ">End Time</label>
                      <input
                        type="time"
                        className="form-control"
                        name="endTime"
                        defaultValue={convertTo24HourFormat(
                          opratingHour[dayIndex]?.endTime
                        )}
                        onChange={(e) =>
                          handleOpratingInputChange(e, dayIndex, "endTime")
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center m-0"
                    style={{ gap: "6px" }}
                  >
                    <input
                      className="mb-4"
                      type="checkbox"
                      checked={opratingHour[dayIndex]?.status}
                      onChange={(e) =>
                        handleOpratingcheck(e, dayIndex, "status")
                      }
                    />
                    <label className="m-0 mb-3">Closed</label>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="d-flex justify-content-end">
            <button
              className={`btn btn-sm ${loader ? "disabled" : ""}`}
              disabled={loader}
              type="submit"
              style={
                loader
                  ? {
                      pointerEvents: "none",
                      opacity: 0.5,
                      backgroundColor: "#F36C03",
                      border: "none",
                      color: "white",
                    }
                  : {
                      pointerEvents: "auto",
                      backgroundColor: "#F36C03",
                      border: "none",
                      color: "white",
                    }
              }
            >
              {loader ? (
                <div
                  className="d-flex align-items-center justify-content-center "
                  style={{ gap: "5px" }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </div>
              ) : (
                "Save Details"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
