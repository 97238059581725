import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { happyhourApi, deleteHappyHourApi } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import moment from "moment";
import animationData from "..//../images/loding.json";
import { variable } from "../components/Variable";
import FilterSearch from "../components/Filters/FilterSearch";
import { toast, ToastContainer } from "react-toastify";
export default function HappyHoursList(props) {
  const venderId = props?.location?.state?.item;
  const [loader, setLoader] = useState(false);
  const [happyHourList, setHappyHourList] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  async function hendleHappyHour() {
    setLoader(true);
    const res = await happyhourApi(venderId, startDate, endDate);
    setHappyHourList(res);
    setLoader(false);
  }

  async function hendleDeleteHappyHour(id) {
    const response = await deleteHappyHourApi(id)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Delete successFull`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          hendleHappyHour();
        }
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error(`❌${error?.response?.data?.data}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  useEffect(() => {
    if (endDate.length > 0) {
      hendleHappyHour();
    }
  }, [endDate]);

  useEffect(() => {
    hendleHappyHour();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#f2f7f7" cx="5" cy="12" r="2"></circle>
        <circle fill="#f2f7f7" cx="12" cy="12" r="2"></circle>
        <circle fill="#f2f7f7" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <PageTitle activeMenu="Happy Hours" motherMenu="Vendor" />
        <FilterSearch
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          Type={"off"}
          type={"search"}
          dateFilter={"on"}
        />
        {happyHourList?.data?.data?.happyHours?.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h2>No happyhour</h2>
          </div>
        ) : (
          <Col>
            {loader ? (
              <div className="">
                <Lottie
                  style={{
                    height: "200px",
                    marginTop: "45px",
                    padding: "30px",
                  }}
                  animationData={animationData}
                />
              </div>
            ) : (
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead style={{ color: "black" }}>
                      <tr>
                        <th>
                          <strong>DAY</strong>
                        </th>
                        <th>
                          <strong>START TIME</strong>
                        </th>
                        <th>
                          <strong>END TIME</strong>
                        </th>
                        <th>
                          <strong>DEALS</strong>
                        </th>
                        <th>
                          <strong>Offer</strong>
                        </th>
                        <th>
                          <strong>Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {happyHourList?.data?.data?.happyHours?.map((item) => (
                        <tr key={item._id}>
                          <td>{item.day}</td>
                          {moment(item?.startTime).format("LT") ===
                          "Invalid date" ? (
                            <td>{item?.startTime}</td>
                          ) : (
                            <td>{moment(item?.startTime).format("LT")}</td>
                          )}

                          {moment(item?.endTime).format("LT") ===
                          "Invalid date" ? (
                            <td>{item?.endTime}</td>
                          ) : (
                            <td>{moment(item?.endTime).format("LT")}</td>
                          )}
                          <td>
                            {item?.deals?.map((ele, i) => (
                              <div>
                                {ele.deal.length > 40
                                  ? ele.deal.slice(0, 30) + "..."
                                  : ele.deal}
                              </div>
                            ))}
                          </td>
                          <td className="" style={{ minWidth: "80px" }}>
                            {item?.deals?.map((ele, i) => (
                              <div className="">
                                {ele.type === "value" && (
                                  <span className="d-flex">
                                    ${ele.price} off
                                  </span>
                                )}
                                {ele.type === "Fixed" && (
                                  <span>${ele.price}</span>
                                )}
                                {ele.type === "percentage" && (
                                  <span>{ele.price}% Off</span>
                                )}
                                {ele.type === "2 for 1" && (
                                  <div className="d-flex flex-column">
                                    <div>${ele.price}(2 for 1)</div>
                                    {/* <div>$ {ele.price}</div> */}
                                  </div>
                                )}
                              </div>
                            ))}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn  i-false "
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleDeleteHappyHour(item._id);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/edit-happyHours",
                                      state: item,
                                    });
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            )}
          </Col>
        )}
      </div>
    </>
  );
}
