import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import { ToastContainer, toast } from "react-toastify";
import { sendEmailVendersApi } from "../components/APIs/Api";
import animationData from "../../images/loding.json";

export default function ShootEmail({ show, onHide }) {
  const [loader, setLoader] = useState(false);

  //======================SendEmail To All Vendor========================
  async function hendleSendEmail() {
    setLoader(true);
    onHide()
    const res = await sendEmailVendersApi();
    if (res.status === 200) {
      toast.success("Send Emails Successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    } else {
        toast.danger("Send Emails Successful!", {
            position: toast.POSITION.TOP_RIGHT,
          });
    }
  }

  return (
    <>
      <Modal className="modal fade" show={show} centered>
        {loader ? (
          <div className="">
            <Lottie
              style={{ height: "200px", marginTop: "45px", padding: "30px" }}
              animationData={animationData}
            />
          </div>
        ) : (
          <div className="">
            <h5 className="p-2 text-center">
              Are You Sure you want to send Email all Vender
            </h5>

            <div
              className=" d-flex justify-content-center p-2"
              style={{ gap: "10px" }}
            >
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger btn-sm"
              >
                {" "}
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-info btn-sm"
                onClick={() => hendleSendEmail()}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
