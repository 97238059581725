// import React from 'react'
import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../components/common/Pagination";
import PageTitle from "../layouts/PageTitle";
import { paymentListApi } from "../components/APIs/Api";

export default function PaymentsList() {
  const [loader, setLoader] = useState(true);
  const [paymentList, setPaymentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 10;

  //======================fetch payment list Api =================
  async function hendlePaymentList() {
    setLoader(true);
    const res = await paymentListApi(currentPage, itemsPerPage);
    setPaymentList(res);
    setLoader(false);
  }

  const totalItems = paymentList?.data?.data?.total || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);


  useEffect(() => {
    hendlePaymentList();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    hendlePaymentList();
  }, []);

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <PageTitle activeMenu="Payments List" motherMenu="Payments" />

        {paymentList?.data?.data?.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h2>No Payments List</h2>
          </div>
        ) : (
          <Col>
            {loader ? (
              <div className="">
                <Lottie
                  style={{
                    height: "200px",
                    marginTop: "45px",
                    padding: "30px",
                  }}
                  animationData={animationData}
                />
              </div>
            ) : (
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead style={{ color: "black" }}>
                      <tr>
                        <th>
                          <strong>Payment Id</strong>
                        </th>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>Amount</strong>
                        </th>
                        <th>
                          <strong>plan</strong>
                        </th>
                        <th>
                          <strong>Date</strong>
                        </th>
                        <th>
                          <strong>Time</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentList?.data?.data?.payment?.map((item, index) => (
                        <tr key={item._id}>
                          <td>{item?._id}</td>

                          <td>{item?.vendor?.userName}</td>
                          <td>${item?.amount}</td>
                          <td>{item?.plan}</td>
                          <td>
                          {moment(item?.paymentDate).format('ll')}
                          </td>
                          <td>
                            {moment(item?.paymentDate).format('LT')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {paymentList?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {currentPage * itemsPerPage + 1} to{" "}
                          {totalItems > (currentPage + 1) * itemsPerPage
                            ? (currentPage + 1) * itemsPerPage
                            : totalItems}{" "}
                          of {totalItems} entries
                        </div>
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        )}
      </div>
    </>
  );
}
